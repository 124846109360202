import React, { useEffect} from 'react';

import {  Grid, Typography,IconButton } from '@material-ui/core';
import useStyles from '../../styles';
import Dropzone from './Dropzone';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export default function Documents(props) {
    const classes = useStyles();
    const {formField, mode, files} = props;
    const SupportingDocumentsIntro = props.init && props.init.formTexts.SupportingDocumentsIntro;
    const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom> Supporting documents {mode == "Edit"?"*":''} </Typography>;
    const onEdit = (step) => {
        if(props.onEdit) {
          props.onEdit(step);
        }
       }
  const edit = mode == "Review" && <IconButton aria-label="edit" onClick={()=>onEdit(3)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  return (
      <div className={classes.documents}>
       {mode == "Review" && 
       <Grid container>
           <Grid item xs={12}>
            <List>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    {title}
                </AccordionSummary> 
                <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                  <ListItem button>
                    <div>
                        <ol>
                            {files}
                        </ol>
                        </div>
                    </ListItem>
                  </Grid>
                  <Grid item xs={12}>
                        {edit}
                      </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </List>
            </Grid></Grid>
       }
       {mode == "Edit" && <>
        {title}
            <div dangerouslySetInnerHTML={{__html:SupportingDocumentsIntro}}></div>
            <Dropzone onFilesAdded = {props.handleAttachments} filesAdded={props.attachments} onFilesRemoved={props.onFilesRemoved}/>
        </>}
    </div>
    );
}