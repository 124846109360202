import React from 'react';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import useStyles from '../../styles';
import Dropzone from './Dropzone';
import {  FormControlLabel,  Switch} from '@material-ui/core';
import { InputField, SelectField, InputAdornmentField, CheckboxField} from '../../../fields';


export default function Documents(props) {
    const classes = useStyles();
    const {formField} = props;
    const SupportingDocumentsIntro = props.init && props.init.formTexts.SupportingDocumentsIntro;
    
    return (
        <>
            <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
            Supporting Documents*
            </Typography>
            <div dangerouslySetInnerHTML={{__html:SupportingDocumentsIntro}}></div>
            <Dropzone onFilesAdded = {props.handleAttachments} filesAdded={props.attachments} onFilesRemoved={props.onFilesRemoved}/>
        </>
    );
}