import * as Yup from 'yup';
import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    charitableWorkArea,
  }
} = eligibilityCheckFormModel;


export default [
  Yup.object().shape({
    [charitableWorkArea.name]: Yup.array().min(1, `${charitableWorkArea.requiredErrorMsg}`),
    /* [lastFYTurnover.name]: Yup.number()
    .required(`${lastFYTurnover.requiredErrorMsg}`) */
  })
];
