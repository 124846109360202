import React, { useEffect } from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { InputRichTextField, CheckboxField } from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import makeStyles from '../fields/styles';
import { FormMode } from '../models/FormMode';

export default function OrganisationDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    formField: { q22, q23, q24, q25 }, getField, mode } = props;
  const { values: formValues } = useFormikContext();
  const fieldQ7 = props.init && getField("FR03Question07");
  const fieldQ9 = props.init && getField("FR03Question09");
  const fieldQ10 = props.init && getField("FR03Question10");
  const fieldQ11 = props.init && getField("FR03Question11");
  const fieldQ12 = props.init && getField("FR03Question12");

  const question22Desc = props.init && props.init.formTexts.Question7;
  const question23Desc = props.init && props.init.formTexts.Question10;
  const question25Desc = props.init && props.init.formTexts.Question12;

  const modules = {
    toolbar: null
  };
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;
  const aboutOrg = props.init && props.init.formTexts.AboutOrg;

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }
  const edit = mode == FormMode.Review && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

  return (
    <div className={classes.aboutOrg}>
      {(mode == FormMode.Review) &&
        <List><Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {title}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q22.label} ${fieldQ7.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q22} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q23.label} ${fieldQ10.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q23} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q24.label} ${fieldQ11.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q24} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q25.label} ${fieldQ12.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q25} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12} sm={12}>
                {edit}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion></List>
      }
      {(mode == FormMode.Stage) &&
        <List><Accordion expanded={true}>
          <AccordionSummary
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {title}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q22.label} ${fieldQ7.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q8} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q23.label} ${fieldQ9.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q23} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q25.label} ${fieldQ11.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q25} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q24.label} ${fieldQ10.description}`} secondary={formValues.q24 ? "Yes" : "No"} /></ListItem>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion></List>
      }
      {mode === FormMode.Edit && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: aboutOrg }}></div>
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q22.name}
              label={`${q22.label} ${fieldQ7.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question22Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q23.name}
              labelClass={classesFields.labelMultiline}
              quillClass={classesFields.q11}
              label={`${q23.label} ${fieldQ10.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question23Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q24.label} ${fieldQ11.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q24} modules={modules} /></ListItem></div>
            {/*   <InputRichTextField
              name={q24.name}
              labelClass={classesFields.labelMultiline}
              quillClass={classesFields.q11}
              label={`${q24.label} ${fieldQ11.description} *`}
              fullWidth
              color="secondary" variant="outlined"
            /> */}
          </Grid>

          <Grid item xs={12}>
            <InputRichTextField
              name={q25.name}
              labelClass={classesFields.labelMultiline}
              quillClass={classesFields.q11}
              label={`${q25.label} ${fieldQ12.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question25Desc}
            />
          </Grid>

        </Grid>
      </>}
    </div>
  );
}
