import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  TextField
} from '@material-ui/core';
import useStyles from './styles';
import SimplePopover from './SimplePopover';

function InputAdornmentField(props) {
  const classes = useStyles();
  const { label, data, description, type, ...rest} = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');

  function _renderHelperText() {
    const helperText = <FormHelperText>{error}</FormHelperText>;
    const popover = <SimplePopover {...props} content={description}/>
    if((touched && error) || description) {
      return <div> {(touched && error)? helperText:''} {description ? popover:''} </div>
    }
  }

  return (
    <div>
    <TextField
      className={classes.multiText}
      type={type}
      label={label}
      error={meta.touched && meta.error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      autoComplete="off"
    >
    </TextField>
    </div>
  );
}
export default InputAdornmentField;
