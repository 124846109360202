import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Grid, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ListItem from '@material-ui/core/ListItem';
import useStyles from './styles';


const modules = {
  toolbar: null
};

function getStep(step, classes) {
    let {values} = step;
    return  <List>  
                {
                    Object.keys(values).map((key,index)=> {
                        if(key.indexOf('16.') > -1 || key.indexOf('17.') > -1 || key.indexOf('18.') > -1 || key.indexOf('19.') > -1 || key.indexOf('20.') > -1 || key.indexOf('21.') > -1 || key.indexOf('22.') > -1 || key.indexOf('23.') > -1 || key.indexOf('24.') > -1 || key.indexOf('25.') > -1 || key.indexOf('26.') > -1 || key.indexOf('27.') > -1 || key.indexOf('30.') > -1 || key.indexOf('31.') > -1 || key.indexOf('32.') > -1) {
                               return <div className={classes.reviewRichText}><ListItemText primary={key}/><ReactQuill theme="snow" readOnly={true} value={values[key]} modules={modules}/></div>
                        } else if(key.indexOf('50.') > -1) {
                         return <div>
                              <ul>
                                  {values[key]}
                              </ul>
                          </div>
                        } else {
                            return <ListItem button><ListItemText primary={key} secondary={values[key]}/></ListItem>
                        }
                    })
                }
            </List>
}

export default function AccordionField(props) {
  const classes = useStyles();
  const {steps} = props;
  const personalStep = steps && steps.find(s=>s.title == "Personal & Organisation details");
    return (
        <div className={classes.root}>
          {personalStep && <Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="h1" variant="h6" className={classes.subTitle}>{personalStep.title}</Typography>
            </AccordionSummary> 
            <AccordionDetails>
              <Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                     <ListItem button><ListItemText primary="1. Saluation" secondary={personalStep.values["1. Saluation"]}/></ListItem>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="2. First Name" secondary={personalStep.values["2. First Name"]}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary="3. Last Name" secondary={personalStep.values["3. Last Name"]}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="4. Job Title" secondary={personalStep.values["4. Job Title"]}/></ListItem>
                  </Grid>
                
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary="5. Email Address" secondary={personalStep.values["5. Email Address"]}/></ListItem>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="6. Phone Number" secondary={personalStep.values["6. Phone Number"]}/></ListItem>
                  </Grid>
              
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary="7. Address" secondary={personalStep.values["7. Address"]}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="8. Postcode" secondary={personalStep.values["8. Postcode"]}/></ListItem>
                  </Grid>
         
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary="9. Organisation Name" secondary={personalStep.values["9. Organisation Name"]}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="10. Organisation Type" secondary={personalStep.values["10. Organisation Type"]}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="11. Website" secondary={personalStep.values["11. Website"]}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary="12. Registered Charity Number" secondary={personalStep.values["12. Registered Charity Number"]}/></ListItem>
                  </Grid>
                
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="13. Registered Company Number" secondary={personalStep.values["13. Registered Company Number"]}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary="14. Where do you deliver your charitable work?" secondary={personalStep.values["14. Where do you deliver your charitable work?"]}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary="15. What was your total income in the previous financial year(£)?" secondary={personalStep.values["15. What was your total income in the previous financial year(£)?"]}/></ListItem>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion> }

        {steps.length > 0 && steps.filter(s=>s.title != "Personal & Organisation details") .map((step, index)=>
             <Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography component="h1" variant="h6" className={classes.subTitle}>{step.title}</Typography>
            </AccordionSummary> 
            <AccordionDetails>
              <Typography>
                {getStep(step, classes)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}  
      </div>
    )
}
