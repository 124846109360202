import * as Yup from 'yup';
import moment from 'moment';
import formModel from './formModel';
const {
  formField: {
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    //organisationIDNumber,
     regCharityNumber,
    regCompanyNumber, 
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    q7,
    q8,
    q9,
    q10,
    q11,
 /* challenges,
    orgPurposeVisionValues,
    orgWorkWith,
    sustainableChange,
    percentYoungPeopleEmployment,
    orgImpact,
    orgYoungPeopleWork,
    orgGovernanceArrangements,
    purposeOfFunding, 
    orgFutureResilient, 
    orgLinkStrategicPlans, 
    orgSupportYoungPeople,
    projectCost, 
    requestedFunding, 
    orgCoverShortfall,
    orgMesaureImpactGrant, 
    orgCommitmentLearning,
    orgBerkeleyGroupSupportYourOrg,
    safeguardingPolicy,*/
    attachments
  }
} = formModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const postcodeRegExp =  /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;
const urlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const currencyRegExp = /^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;
const regCharityOrCompanyNumberRegExp = /^[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;
const regCharityOrCompanyNumberMin = 5;
const regCharityOrCompanyNumberMax = 10;
const requiredField = "This field is required";

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
    .required(`${firstName.requiredErrorMsg}`)
    .max(255, `${firstName.label} First Name exceeded limited charcters!`),
    [lastName.name]: Yup.string()
      .max(255, `${lastName.label} Last Name exceeded limited charcters!`)
      .required(`${lastName.requiredErrorMsg}`),
    [jobTitle.name]: Yup.string()
      .required(`${jobTitle.requiredErrorMsg}`)
      .max(255, `${jobTitle.label} Job Title exceeded limited charcters!`),
    [email.name]: Yup.string()
      .required(`${email.requiredErrorMsg}`)
      .email(`Invalid ${email.label} Email Address`),
    [confirmEmail.name]: Yup.string()
      .required(`${confirmEmail.requiredErrorMsg}`)
      .when("email", {
        is: email => (email && email.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("email")], `${email.label} Confirm Email Address doesn't match!`)
      }),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
       .matches(phoneRegExp, `Invalid ${phone.label} Phone number!`) 
      .test('len', `Invalid ${phone.label} Phone number!`, val => val && val.length === 11),
    [orgName.name]: Yup.string()
    .required(`${orgName.requiredErrorMsg}`)
    .max(255, `${orgName.label} Organisation name exceeded limited charcters!`),
    [orgType.name]: Yup.string().required(`${orgType.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [postcode.name]: Yup.string()
      .required(`${postcode.requiredErrorMsg}`)
      .matches(postcodeRegExp, `Invalid ${postcode.label} Postcode`),
    [website.name]: Yup.string()
      .required(`${website.requiredErrorMsg}`)
      .matches(urlRegExp, `${website.label} Website must start with either http or https`),
    [charitableWorkArea.name]: Yup.array().min(1, `${charitableWorkArea.requiredErrorMsg}`),
    [lastFYTurnover.name]: Yup.string()
    .required(`${lastFYTurnover.requiredErrorMsg}`)
  }),
 Yup.object().shape({
    [q1.name]: Yup.string()
      .required(`${q1.requiredErrorMsg}`)
      .test('len', `${q1.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q2.name]: Yup.string()
      .required(`${q2.requiredErrorMsg}`)
      .test('len', `${q1.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q3.name]: Yup.array().min(1, `${q3.requiredErrorMsg}`),
    [q4.name]: Yup.string().required(`${q4.requiredErrorMsg}`)
    .test('len', `${q4.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
    [q5.name]: Yup.string()
      .required(`${q5.requiredErrorMsg}`)
      .test('len', `${q5.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [q6.name]: Yup.number().required(`${q6.requiredErrorMsg}`),
    [q7.name]:Yup.number()
      .required(`${q7.requiredErrorMsg}`)
      .max(30000, `This field must not exceed £30000.`),
  }),
   Yup.object().shape({
    [q8.name]: Yup.string()
    .required(`${q8.requiredErrorMsg}`)
    .test('len', `${q8.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
    [q9.name]: Yup.string()
    .required(`${q9.requiredErrorMsg}`)
    .test('len', `${q9.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
    //[q10.name]: Yup.boolean().oneOf([true], `${q10.requiredErrorMsg}`),
    [q11.name]: Yup.string()
    .required(`${q11.requiredErrorMsg}`)
    .test('len', `${q11.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    )
  }) ,
  Yup.object().shape({
    [attachments.name]: Yup.array().min(1, `${attachments.requiredErrorMsg}`),
  }) 
];
