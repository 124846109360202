import formModel from './formModel';
const {
  formField: {
    salutation,
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    //organisationIDNumber,
    regCharityNumber, 
    regCompanyNumber, 
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    q7,
    q8,
    q9,
    q10,
    q11
  }
} = formModel;
 
export default {
  [salutation.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Mr':'',
  [firstName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'B':'',
  [lastName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'A':'',
  [jobTitle.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'D':'',
  [email.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'U@u.com':'',
  [confirmEmail.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'U@u.com':'',
  [phone.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '07809999999':'',
  [orgName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'O':'',
  [address.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'T':'',
  [postcode.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'SW1 5KL':'',
  [website.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'https://www.bg.co.uk':'',
  [regCharityNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'123433':'',
  [regCompanyNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'23456':'',
  //[organisationIDNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '123456':'',
  [orgType.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Charity':'',
  [lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '34567':'',
  [charitableWorkArea.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? ['Kent', 'Hampshire']:[],

  [q1.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [q2.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [q3.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? ['Operations that are fit for purpose', 'People and Culture']:[],
  [q4.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [q5.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [q6.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '505050':'',
  [q7.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '22222':'',

  [q8.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':null,
  [q9.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [q10.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? true:false,
  [q11.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
}; 