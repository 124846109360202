import React, { useEffect} from 'react';
import { Grid, Typography, Button,IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { InputRichTextField, InputAdornmentField, SelectMultipleField} from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import makeStyles from '../fields/styles';

export default function ProjectDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    formField: {q1,q2,q3,q4,q5,q6,q7}, getChoices, getField, mode
  } = props;
  const { values: formValues } = useFormikContext();

  const q3Choices = props.init && getChoices("FR02Question03") && getChoices("FR02Question03");
  const fieldQ1 = props.init && getField("FR02Question01");
  const fieldQ2 = props.init && getField("FR02Question02");
  const fieldQ3 = props.init && getField("FR02Question03");
  const fieldQ4 = props.init && getField("FR02Question04");
  const fieldQ5 = props.init && getField("FR02Question05");
  const fieldQ6 = props.init && getField("FR02Question06");
  const fieldQ7 = props.init && getField("BGRequestedFunding");

  const modules = {
    toolbar: null
  };
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>About your project</Typography>;
  const aboutProject = props.init && props.init.formTexts.AboutProject;

  const onEdit = (step) => {
    if(props.onEdit) {
      props.onEdit(step);
    }
   }
  const edit = mode == "Review" && <IconButton aria-label="edit" onClick={()=>onEdit(1)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  return (
    <div className={classes.aboutProject}>
       {mode == "Review" && 
        <List><Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                {title}
            </AccordionSummary> 
            <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q1.label} ${fieldQ1.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q1} modules={modules}/></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q2.label} ${fieldQ2.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q2} modules={modules}/></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q3.label} ${fieldQ3.description}`} secondary={formValues.q3.join(', ')}/></ListItem>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q4.label} ${fieldQ4.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q4} modules={modules}/></ListItem></div>
              </Grid>
              
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q5.label} ${fieldQ5.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q5} modules={modules}/></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q6.label} ${fieldQ6.description}`} secondary={'£' +formValues.q6.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/></ListItem> 
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q7.label} ${fieldQ7.description}`} secondary={'£' +formValues.q7.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/></ListItem> 
              </Grid>
              <Grid item xs={12} sm={12}>
                    {edit}
                  </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion></List>
      }
      {mode == "Edit" && <>
        {title}
         <Grid container spacing={3}>
           <Grid item xs={12}>
                <div dangerouslySetInnerHTML={{__html:aboutProject}}></div>
            </Grid>
            <Grid item xs={12}>
              <InputRichTextField
                name={q1.name}
                label={`${q1.label} ${fieldQ1.description} *`}
                fullWidth
                color="secondary" variant="outlined"
              />
          </Grid>
        <Grid item xs={12}>
         <InputRichTextField
            name={q2.name}
            label={`${q2.label} ${fieldQ2.description} *`}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
         <Grid item xs={12}>
             <SelectMultipleField
                name={q3.name}
                label={`${q3.label} ${fieldQ3.description} *`}
                data={q3Choices}
                value={q3.value}
                fullWidth
                variant="outlined"
                color="secondary"
            />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={q4.name}
            label={`${q4.label} ${fieldQ4.description} *`}
            fullWidth
            color="secondary" variant="outlined"
            quillClass={classesFields.q4}
          />
        </Grid>
       
        <Grid item xs={12}>
          <InputRichTextField
            name={q5.name}
            label={`${q5.label} ${fieldQ5.description} *`}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
            <InputAdornmentField
                name={q6.name}
                label={`${q6.label} ${fieldQ6.description} *`}
                adornment="£"
                fullWidth
                type="number"
                color="secondary" variant="outlined"
            />
        </Grid>
        <Grid item xs={12}>
           <InputAdornmentField
                name={q7.name}
                label={`${q7.label} ${fieldQ7.description} *`}
                adornment="£"
                fullWidth
                type="number"
                color="secondary" variant="outlined"
            />
        </Grid>
      </Grid>
      </>}
    </div>
  );
}
