import React, { useEffect} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputRichTextField, InputAdornmentField} from '../../../fields';
import useStyles from '../../styles';

export default function ProjectDetails(props) {
  const classes = useStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    formField: { purposeOfFunding, orgFutureResilient, orgLinkStrategicPlans, sustainableChange, projectCost, requestedFunding, orgCoverShortfall,orgMesaureImpactGrant, orgCommitmentLearning, orgBerkeleyGroupSupportYourOrg}, getChoices
  } = props;


  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
      About the Project
        </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputRichTextField
            name={purposeOfFunding.name}
            label={purposeOfFunding.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgFutureResilient.name}
            label={orgFutureResilient.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgLinkStrategicPlans.name}
            label={orgLinkStrategicPlans.label}
            fullWidth
            labelClass={true}
            color="secondary" variant="outlined"
            quillClass={orgLinkStrategicPlans.name}
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={sustainableChange.name}
            label={sustainableChange.label}
            fullWidth
            color="secondary" variant="outlined"
            
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgMesaureImpactGrant.name}
            label={orgMesaureImpactGrant.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
           <InputAdornmentField
                name={projectCost.name}
                label={projectCost.label}
                adornment="£"
                fullWidth
                type="Number"
                color="secondary" variant="outlined"
            />
        </Grid>

        <Grid item xs={12}>
           <InputAdornmentField
                name={requestedFunding.name}
                label={requestedFunding.label}
                adornment="£"
                fullWidth
                type="Number"
                color="secondary" variant="outlined"
            />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgCoverShortfall.name}
            label={orgCoverShortfall.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
      
        <Grid item xs={12}>
          <InputRichTextField
            name={orgCommitmentLearning.name}
            label={orgCommitmentLearning.label}
            fullWidth
            color="secondary" variant="outlined"
            quillClass={orgCommitmentLearning.name} labelClass={true}
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgBerkeleyGroupSupportYourOrg.name}
            label={orgBerkeleyGroupSupportYourOrg.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
