export default {
  formId: 'mainForm',
  formField: {
    //accessibility
    language: {
      name: 'language',
      label: '1.',
      requiredErrorMsg: 'This field is required'
    },
    format: {
      name: 'format',
      label: '2.',
      requiredErrorMsg: 'This field is required'
    },
    consider: {
      name: 'consider',
      label: '3.',
      requiredErrorMsg: 'This field is required'
    },
    adjustments: {
      name: 'adjustments',
      label: '4.',
      requiredErrorMsg: 'This field is required'
    },
    adjustmentsTxt: {
      name: 'adjustmentsTxt',
      label: '',
      requiredErrorMsg: 'This field is required'
    },
    //personal
    salutation: {
      name: 'salutation',
      label: '1.'
    },
    firstName: {
      name: 'firstName',
      label: '2.',
      requiredErrorMsg: 'This field is required'
    },
    lastName: {
      name: 'lastName',
      label: '3.',
      requiredErrorMsg: 'This field is required'
    },
   
    jobTitle: {
      name: 'jobTitle',
      label: '4.',
      requiredErrorMsg: 'This field is required'
    },
    email: {
      name: 'email',
      label: '5.',
      requiredErrorMsg: 'This field is required'
    },
    confirmEmail: {
      name: 'confirmEmail',
      label: '5. Confirm',
      requiredErrorMsg: 'This field is required'
    },
    phone: {
      name: 'phone',
      label: '6.',
      requiredErrorMsg: 'This field is required'
    },
    address: {
      name: 'address',
      label: '7.',
      requiredErrorMsg: 'This field is required'
    },
    postcode: {
      name: 'postcode',
      label: '8.',
      requiredErrorMsg: 'This field is required'
    },
    orgName: {
      name: 'orgName',
      label: '9.',
      requiredErrorMsg: 'This field is required'
    },
    orgType: {
      name: 'orgType',
      label: '10.',
      requiredErrorMsg: 'This field is required'
    },
    website: {
      name: 'website',
      label: '11.',
      requiredErrorMsg: 'This field is required'
    },
    organisationIDNumber: {
      name: 'organisationIDNumber',
      label: '12.',
      requiredErrorMsg: 'This field is required'
    },
     regCharityNumber: {
      name: 'regCharityNumber',
      label: '12.',
      requiredErrorMsg: 'This field is required'
    },
    regCompanyNumber: {
      name: 'regCompanyNumber',
      label: '13.',
      requiredErrorMsg: 'This field is required'
    },
    charitableWorkArea: {
      name: 'charitableWorkArea',
      label: '14.',
      requiredErrorMsg: 'This field is required'
    },
    lastFYTurnover: {
      name: 'lastFYTurnover',
      label: '15.',
      requiredErrorMsg: 'This field is required'
    },
    q8: {
      name: 'q8',
      label: '16.',
      requiredErrorMsg: 'This field is required'
    },
    q9: {
      name: 'q9',
      label: '17.',
      requiredErrorMsg: 'This field is required'
    },
    
    q11: {
      name: 'q11',
      label: '18.',
      requiredErrorMsg: 'This field is required'
    },

     /** Equity, diversity and inclusion **/
     div1: {
      name: 'div1',
      label: '1.',
      requiredErrorMsg: 'This field is required',
      description:'Please select any category that you anticipate that the majority (75% or more) of people supported or benefiting will identify. You may select from multiple categories.'
    },
    div2: {
      name: 'div2',
      label: '2.',
      requiredErrorMsg: 'This field is required',
      description:'Examples: Men and boys; Care experienced young people; People recovering from alcohol addiction; People with experience of the criminal justice system; Sex workers'
    },
    div3: {
      name: 'div3',
      label: '3.',
      requiredErrorMsg: 'This field is required',
      description:'By leadership of the organisation we mean that the majority of the key decision-makers have a shared identity. As an indication, this might be 75% or more of the Board of Trustees/ Management Committee AND 50% or more of senior staff identify as from a community or characteristic'
    },
    div4: {
      name: 'div4',
      label: '4.',
      requiredErrorMsg: 'This field is required'
    },
    div5: {
      name: 'div5',
      label: '5.',
      requiredErrorMsg: 'This field is required',
      description:'Do you feel that the information provided in this section about the people you support or your leadership may disclose sensitive information that should not be shared externally (e.g. information about identifiable individuals)?'
    },
    div5txt: {
      name: 'div5txt',
      label: '',
      requiredErrorMsg: 'This field is required'
    },



     /** PROJECT */
     q16: {
      name: 'q16',
      label: '16.',
      requiredErrorMsg: 'This field is required'
    },
    q17: {
      name: 'q17',
      label: '17.',
      requiredErrorMsg: 'This field is required'
    },
    q18: {
      name: 'q18',
      label: '18.',
      requiredErrorMsg: 'This field is required'
    },
    q19: {
      name: 'q19',
      label: '19.',
      requiredErrorMsg: 'This field is required'
    },
    q20: {
      name: 'q20',
      label: '20.',
      requiredErrorMsg: 'This field is required'
    },
    q21: {
      name: 'q21',
      label: '21.',
      requiredErrorMsg: 'This field is required'
    },
    /** ORG **/
    q22: {
      name: 'q22',
      label: '22.',
      requiredErrorMsg: 'This field is required'
    },
    q23: {
      name: 'q23',
      label: '23.',
      requiredErrorMsg: 'This field is required'
    },
    q24: {
      name: 'q24',
      label: '24.',
      requiredErrorMsg: 'This field is required'
    },
    q25: {
      name: 'q25',
      label: '25.',
      requiredErrorMsg: 'This field is required'
    },
    safeguardingPolicy:{
      name: 'safeguardingPolicy',
      label: 'Do you have an up to date Safeguarding Policy?',
      requiredErrorMsg: 'This field is required'
    },
    attachments:{
      name: 'attachments',
      label: 'Supporting Documents',
      requiredErrorMsg: 'This field is required'
    },
  }
};
