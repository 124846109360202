import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    eligibility03,
    eligibility04,
    charitableWorkArea,
    eligibility05,
    eligibility06,
    eligibility07,
    eligibility08,
    eligibility09,
    eligibility10,
  }
} = eligibilityCheckFormModel;

const DEVTEXT = 'development';

 export default {
  [regCharity.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  //[lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT? '125099':'',
  [eligibility03.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility04.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [charitableWorkArea.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT? ['Kent', 'Hampshire']:[],
  [eligibility05.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility06.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility07.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility08.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility09.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
  [eligibility10.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT?true:false,
}; 
