import React from 'react'
import {
    Typography,
    Grid
  } from '@material-ui/core';
  import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';


function Success(props) {
  const classes = useStyles();
  const successText = props.init && props.init.formTexts.SuccessText;

    return (
      <div className={classes.success}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.info} variant="outlined" color="primary">
              <CardContent>
                <div dangerouslySetInnerHTML={{__html:successText}}></div>
              </CardContent>
            </Card> 
            </Grid>
          </Grid>
        </div>
    )
}

export default Success
