import React, { useState, useRef } from 'react';
import {
    Button,
    Typography,
    CircularProgress,
    Grid
} from '@material-ui/core';
import useStyles from '../styles';
import { Formik, Form } from 'formik';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import webinarValidationSchema from './models/webinarValidationSchema';
import webinarFormModel from './models/webinarFormModel';
import webinarFormInitialValues from './models/webinarFormInitialValues';
import { Stopper, QuestionsForm } from './sections';
import ReCAPTCHA from "react-google-recaptcha";
import { Success } from '../FR03/sections';
import SendIcon from '@material-ui/icons/Send';
import Errors from '../Errors';

const STEPS = {
    Stopper: 0,
    Form: 1,
    Success: 2
}
const steps = ['Stopper', 'Form'];
const { formId, formField } = webinarFormModel;

function renderStepContent(step, props) {
    switch (step) {
        case 0:
            return <Stopper  {...props} />;
        case 1:
            return <QuestionsForm formField={formField} {...props} />;
        default:
            return <div>Not Found</div>;
    }
}

function WebinarForm(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const currentValidationSchema = webinarValidationSchema[activeStep];
    const isLastStep = activeStep === steps.length - 1;
    const [serverErrors, setServerErrors] = useState([]);
    const config = props.config;
    const recaptchaRef = useRef();


    async function _submitForm(values, actions) {
        let token = '';
        if (props.init.humanEnabled) {
            if (recaptchaRef.current) {
                token = await recaptchaRef.current.executeAsync();
                recaptchaRef.current.reset();
            }
        }
        setServerErrors([]);
        let currentCycle = props.currentCycle;
        const postData = {
            ...values,
            cycle: currentCycle,
            initData: props.init,
            token,
        };
        let apiUrl = `${config.ApiUrl}/newwebinar`;
        const response = await fetch(apiUrl,
            {
                body: JSON.stringify(postData),
                method: "POST",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": `Bearer ${config.ApiToken}`
                }
            })
            .catch(error => {
                setServerErrors([{ message: error }]);
                actions.setSubmitting(false);
            });
        const result = await response.json();
        console.log('result', result);
        if (result) {
            if (result.data) {
                setActiveStep(STEPS.Success);
                setServerErrors([]);
                actions.setSubmitting(false);
            }
        }
        else if (result.errors) {
            setServerErrors(result.errors);
            actions.setSubmitting(false);
        } else if (result.error) {
            setServerErrors([{ message: result.error }]);
            actions.setSubmitting(false);
        }

    }

    function _handleSubmit(values, actions) {
        if (isLastStep) {
            _submitForm(values, actions);
        } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    const getField = (name) => {
        let { fields } = props.init;
        let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
        return field;
    }

    const getChoices = (name) => {
        let { fields } = props.init;
        let field = fields && fields.value?.filter((f) => f['name'] == name)[0];
        let choices = field && field['choice']['choices'] ? field['choice']['choices'] : [];
        return choices;
      }


    const introText = props.init && props.init.formTexts.WebinarIntro;
    const formProps = { init: props.init, getField, currentCycle: props.currentCycle, getChoices };

    return (
        <React.Fragment>
            <div className={classes.webinar}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {(activeStep != STEPS.Stopper) && <div> <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                            {props.currentCycle && props.currentCycle.title}
                        </Typography>
                            <Typography component="h6" variant="h6" className={classes.subTitle} style={{ textAlign: 'center', color: '#0000008a' }} gutterBottom>
                                {props.currentCycle && new Date(props.currentCycle.start).toLocaleString("en-GB", {day: "numeric",month: "short",year: "numeric"})}
                            </Typography>
                        </div>}
                        {activeStep === STEPS.Success ?
                            <Success {...formProps} textKey={'WebinarSuccess'} style={{ marginTop: 24 }} />
                            :
                            <Formik
                                initialValues={webinarFormInitialValues}
                                validationSchema={currentValidationSchema}
                                onSubmit={_handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form id={formId}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                {renderStepContent(activeStep, formProps)}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <div>
                                                    <div className={classes.buttons} style={{ marginTop: activeStep === STEPS.Stopper ? -48 : 0,marginRight: activeStep === STEPS.Stopper ? 12 : 0 }}>
                                                        <Button
                                                            disabled={isSubmitting}
                                                            type="submit"
                                                            variant="contained"
                                                            color="secondary"
                                                            className={activeStep === STEPS.Stopper ? classes.buttonStartNow : classes.buttonNext}
                                                            endIcon={isLastStep ? <SendIcon /> : <ArrowRightIcon />}
                                                        > {isLastStep ? 'Submit' : 'Start now'}</Button>
                                                        {isSubmitting && (
                                                            <CircularProgress
                                                                size={24}
                                                                className={classes.buttonProgress}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>


                                        <div className="reCaptcha">
                                            {activeStep == 1 && props.init.humanEnabled && <ReCAPTCHA
                                                sitekey={config.RecaptchaKey}
                                                ref={recaptchaRef}
                                                size="invisible"
                                            />
                                            }
                                        </div>
                                    </Form>
                                )}
                            </Formik>}
                    </Grid>
                </Grid>
            </div>
            {serverErrors && serverErrors.length > 0 && <Errors errors={serverErrors} />}
        </React.Fragment>
    );
}

export default WebinarForm
