import React, { useState, useEffect, useContext } from 'react';
import {
  Grid
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    loading: {
      textAlign: 'center',
    },
    notice: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },

  }),
);

export default function UnknownFR(props) {
    const classes = useStyles();

    return (
      <div class={classes.UnknownFR}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.notice} variant="outlined" color="secondary">
              <CardContent>
                <AnnouncementIcon color="secondary" fontSize="large" style={{ float: 'left' }} />
                <div style={{ float: 'left', marginTop: -9, paddingLeft: 10 }} dangerouslySetInnerHTML={{ __html: props.text }}></div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }