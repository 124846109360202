import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    minWidth: 275,
    width:'100%',
  },
  multiSelect: {
    '& .MuiOutlinedInput-input': {
      fontSize:14,
    },
    '& label':{
      color:theme.palette.secondary.main,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor:'#fff',
      paddingLeft:5,
      paddingRight:5,
      'transform': 'translate(14px, -6px) scale(0.87) !important',
      color:theme.palette.secondary.main,
    },
   
  },
  '.MuiListItem-root': {
    fontSize:14,
  },
  singleText: {
    fontFamily:'Rockwell-MT',

    '& .MuiOutlinedInput-input': {
      fontSize:14,
    },
    '& label':{
      color:theme.palette.secondary.main,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      'transform': 'translate(14px, -6px) scale(0.87) !important',
      'padding-right':4,
      backgroundColor: '#fff',
      color:theme.palette.secondary.main,
    },
    '& input[type=number]::-webkit-inner-spin-button':{
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-outer-spin-button':{
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  multiText: {
    '& label':{
      color:theme.palette.secondary.main,
      marginRight:8
    },
    '& .MuiOutlinedInput-input': {
      fontSize:14,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      'transform': 'translate(14px, -6px) scale(0.87) !important',
      'padding-right':4,
      backgroundColor: '#fff',
      color:theme.palette.secondary.main,
      width: 'auto',
      lineHeight:'1.6em'
    },
    '& input':{
      marginTop:'1.4em'
    },
    '& input[type=number]::-webkit-inner-spin-button':{
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-outer-spin-button':{
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  toggleText: {
    '& .MuiFormControlLabel-label':{
      color:theme.palette.secondary.main,
      position: 'absolute',
      fontSize:14,
      left:0,
      marginRight:50
    },
    '& .MuiSwitch-root': {
      marginTop:26
    },
    '& span.text': {
      marginTop:26,
      marginRight: 16,
    },
    '& .selected': {
      color:theme.palette.secondary.main,
      fontWeight:600
    }
  },
  toggleQ5: {
    '& .MuiFormControlLabel-label':{
      color:'#8aaf45',
      position: 'absolute',
      fontSize:14,
      left:0,
      marginRight:50
    },
    '& .MuiFormControlLabel-labelPlacementStart':{ 
      color:'#8aaf45',
      fontSize:14,
      marginRight:-16
    },
    '& .MuiSwitch-root': {
      marginTop:64
    },
    '& span.text': {
      marginTop:64,
      marginRight: 16,
    },
    '& .selected': {
      color:theme.palette.secondary.main,
      fontWeight:600
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  quill: {
    marginTop:0,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  
  reviewRichText: {
    paddingTop:8,
    paddingBottom:8,
    paddingRight:16,
    paddingLeft:16,
    '&:hover' : {
      backgroundColor:'#fafafa',
      'cursor': 'pointer'
    },
    '& .ql-container': {
      'border':'0px solid #ccc',
    },
    '& .ql-editor': {
      padding:0,
      'cursor': 'pointer'
    }
  },
  subTitle: {
    /* fontSize: theme.typography.pxToRem(15), */
    fontWeight: theme.typography.fontWeightRegular,
    color:theme.palette.secondary.main,
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  }
}));
