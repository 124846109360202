import * as Yup from 'yup';
import moment from 'moment';
import formModel from './formModel';
const {
  formField: {
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    organisationIDNumber,
    /* regCharityNumber,
    regCompanyNumber, */
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    challenges,
 /*    workPriorities, */
    orgPurposeVisionValues,
    orgWorkWith,
    sustainableChange,
    percentYoungPeopleEmployment,
    orgImpact,
    orgYoungPeopleWork,
    orgGovernanceArrangements,
    purposeOfFunding, 
    orgFutureResilient, 
    orgLinkStrategicPlans, 
    orgSupportYoungPeople,
    projectCost, 
    requestedFunding, 
    orgCoverShortfall,
    orgMesaureImpactGrant, 
    orgCommitmentLearning,
    orgBerkeleyGroupSupportYourOrg,
    safeguardingPolicy,
    attachments
  }
} = formModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const postcodeRegExp =  /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/;
const urlRegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
const currencyRegExp = /^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;
const regCharityOrCompanyNumberRegExp = /^[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;
const regCharityOrCompanyNumberMin = 5;
const regCharityOrCompanyNumberMax = 10;
const requiredField = "This field is required";

export default [
  Yup.object().shape({
    [firstName.name]: Yup.string()
    .required(`${firstName.requiredErrorMsg}`)
    .max(255, `${firstName.label} exceeded limited charcters!`),
    [lastName.name]: Yup.string()
      .max(255, `${lastName.label} exceeded limited charcters!`)
      .required(`${lastName.requiredErrorMsg}`),
    [jobTitle.name]: Yup.string()
      .required(`${jobTitle.requiredErrorMsg}`)
      .max(255, `${jobTitle.label} exceeded limited charcters!`),
    [email.name]: Yup.string()
      .required(`${email.requiredErrorMsg}`)
      .email('Invalid Email'),
    [confirmEmail.name]: Yup.string()
      .required(`${confirmEmail.requiredErrorMsg}`)
      .when("email", {
        is: email => (email && email.length > 0 ? true : false),
        then: Yup.string().oneOf([Yup.ref("email")], `${email.label} doesn't match!`)
      }),
    [phone.name]: Yup.string()
      .required(`${phone.requiredErrorMsg}`)
       .matches(phoneRegExp, `Invalid ${phone.label}!`) 
      .test('len', `Invalid ${phone.label}!`, val => val && val.length === 11),
    [orgName.name]: Yup.string()
    .required(`${orgName.requiredErrorMsg}`)
    .max(255, `${orgName.label} exceeded limited charcters!`),
    [orgType.name]: Yup.string().required(`${orgType.requiredErrorMsg}`),
    [address.name]: Yup.string().required(`${address.requiredErrorMsg}`),
    [postcode.name]: Yup.string()
      .required(`${postcode.requiredErrorMsg}`)
      .matches(postcodeRegExp, `Invalid ${postcode.label}`),
    [website.name]: Yup.string()
      .required(`${website.requiredErrorMsg}`)
      .matches(urlRegExp, `${website.label} must start with either http or https`),
    [charitableWorkArea.name]: Yup.array().min(1, `${charitableWorkArea.requiredErrorMsg}`),
    [lastFYTurnover.name]: Yup.string()
    .required(`${lastFYTurnover.requiredErrorMsg}`)
    .matches(currencyRegExp, `Invalid ${lastFYTurnover.label}!`)
  }),
 Yup.object().shape({
    [orgPurposeVisionValues.name]: Yup.string()
      .required(`${orgPurposeVisionValues.requiredErrorMsg}`)
      .test('len', `${orgPurposeVisionValues.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [orgWorkWith.name]: Yup.string()
      .required(`${orgWorkWith.requiredErrorMsg}`)
      .test('len', `${orgWorkWith.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
      [orgSupportYoungPeople.name]: Yup.string().required(`${orgSupportYoungPeople.requiredErrorMsg}`)
      .test('len', `${orgSupportYoungPeople.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [percentYoungPeopleEmployment.name]: Yup.string()
      .required(`${percentYoungPeopleEmployment.requiredErrorMsg}`)
      .test('len', `${percentYoungPeopleEmployment.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [orgImpact.name]: Yup.string()
      .required(`${orgImpact.requiredErrorMsg}`)
      .test('len', `${orgImpact.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    [orgYoungPeopleWork.name]: Yup.string()
      .required(`${orgYoungPeopleWork.requiredErrorMsg}`)
      .test('len', `${orgYoungPeopleWork.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
    /* [orgGovernanceArrangements.name]: Yup.string()
      .required(`${orgGovernanceArrangements.requiredErrorMsg}`)
      .test('len', `${orgGovernanceArrangements.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ), */
  }),
   Yup.object().shape({
   [purposeOfFunding.name]: Yup.string().required(`${purposeOfFunding.requiredErrorMsg}`)
   .test('len', `${purposeOfFunding.requiredErrorMsg}`, val => {
        if(val && val.indexOf('<p><br></p>')) {
          return true;
        }
      }
      ),
   [orgFutureResilient.name]: Yup.string().required(`${orgFutureResilient.requiredErrorMsg}`)
    .test('len', `${orgFutureResilient.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
   [orgLinkStrategicPlans.name]: Yup.string().required(`${orgLinkStrategicPlans.requiredErrorMsg}`)
    .test('len', `${orgLinkStrategicPlans.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
    [sustainableChange.name]: Yup.string()
    .required(`${sustainableChange.requiredErrorMsg}`)
    .test('len', `${sustainableChange.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
  
   [projectCost.name]: Yup.number().required(`${projectCost.requiredErrorMsg}`),
   [requestedFunding.name]: Yup.number().required(`${requestedFunding.requiredErrorMsg}`)
   .max(30000, `This field must not exceed £30000.`),
   [orgCoverShortfall.name]: Yup.string().required(`${orgCoverShortfall.requiredErrorMsg}`)
    .test('len', `${orgCoverShortfall.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
    [orgMesaureImpactGrant.name]: Yup.string().required(`${orgMesaureImpactGrant.requiredErrorMsg}`)
    .test('len', `${orgMesaureImpactGrant.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
   [orgCommitmentLearning.name]: Yup.string().required(`${orgCommitmentLearning.requiredErrorMsg}`)
    .test('len', `${orgCommitmentLearning.requiredErrorMsg}`, val => {
      if(val && val.indexOf('<p><br></p>')) {
        return true;
      }
    }
    ),
  }) ,
  Yup.object().shape({
    [attachments.name]: Yup.array().min(1, `${attachments.requiredErrorMsg}`),
  }) 
];
