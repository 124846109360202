import React, { useEffect } from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { InputRichTextField, InputAdornmentField, SelectMultipleField } from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import makeStyles from '../fields/styles';

export default function ProjectDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    formField: { q16, q17, q18, q19, q20, q21 }, getChoices, getField, mode
  } = props;
  const { values: formValues } = useFormikContext();

  const q3Choices = props.init && getChoices("FR03Question03") && getChoices("FR03Question03");
  const fieldQ1 = props.init && getField("FR03Question08");
  const fieldQ2 = props.init && getField("FR03Question09");
  const fieldQ3 = props.init && getField("FR03Question03");
  const fieldQ4 = props.init && getField("FR03Question07");
  const fieldQ5 = props.init && getField("FR03Question05");
  const fieldQ6 = props.init && getField("FR03Question06");
  const fieldQ7 = props.init && getField("BGRequestedFunding");

  const question19Desc = props.init && props.init.formTexts.Question5;
  const question20Desc = props.init && props.init.formTexts.Question6;
  const requestedFundingDesc = props.init && props.init.formTexts.RequestedFunding;

  const modules = {
    toolbar: null
  };
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;
  const aboutProject = props.init && props.init.formTexts.AboutProject;

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }
  const edit = mode == "Review" && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  return (
    <div className={classes.aboutProject}>
      {mode == "Review" &&
        <List><Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {title}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q16.label} ${fieldQ1.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q16} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q17.label} ${fieldQ3.description}`} secondary={formValues.q17 && formValues.q17.length > 0 ? formValues.q17.join(', ') : ''} /></ListItem>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q18.label} ${fieldQ2.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q18} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q19.label} ${fieldQ5.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q19} modules={modules} /></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q20.label} ${fieldQ6.description}`} secondary={'£' + formValues.q20.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} /></ListItem>
              </Grid>
              <Grid item xs={12}>
                <ListItem button><ListItemText primary={`${q21.label} ${fieldQ7.description}`} secondary={'£' + formValues.q21.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} /></ListItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                {edit}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion></List>
      }
      {mode == "Edit" && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div dangerouslySetInnerHTML={{ __html: aboutProject }}></div>
          </Grid>
          <Grid item xs={12}>
            <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q16.label} ${fieldQ1.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q16} modules={modules} /></ListItem></div>
            {/*    <InputRichTextField
              name={q16.name}
              label={`${q16.label} ${fieldQ1.description} *`}
              fullWidth
              color="secondary" variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={12}>
            <ListItem button><ListItemText primary={`${q17.label} ${fieldQ3.description}`} secondary={formValues.q17 && formValues.q17.length > 0 ? formValues.q17.join(', ') : ''} /></ListItem>
            {/*  <SelectMultipleField
              name={q17.name}
              label={`${q17.label} ${fieldQ3.description} *`}
              data={q3Choices}
              value={q17.value}
              fullWidth
              variant="outlined"
              color="secondary"
            /> */}
          </Grid>
          <Grid item xs={12}>
          <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q18.label} ${fieldQ2.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.q18} modules={modules} /></ListItem></div>
            {/*  <InputRichTextField
              name={q18.name}
              label={`${q18.label} ${fieldQ2.description} *`}
              fullWidth
              color="secondary" variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q19.name}
              label={`${q19.label} ${fieldQ5.description} *`}
              fullWidth
              color="secondary" variant="outlined"
              description={question19Desc}
              />
          </Grid>
          <Grid item xs={12}>
            <InputAdornmentField
              name={q20.name}
              label={`${q20.label} ${fieldQ6.description} *`}
              adornment="£"
              fullWidth
              type="number"
              color="secondary" variant="outlined"
              description={question20Desc}
            />
          </Grid>
          <Grid item xs={12}>
            <InputAdornmentField
              name={q21.name}
              label={`${q21.label} ${fieldQ7.description} *`}
              adornment="£"
              fullWidth
              type="number"
              color="secondary" variant="outlined"
              description={requestedFundingDesc}
            />
          </Grid>
        </Grid>
      </>}
    </div>
  );
}
