import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    charitableWorkArea,
    lastFYTurnover,
    hasYearPublishedAccounts,
    hasYoungPeople,
  }
} = eligibilityCheckFormModel;

 export default {
  [regCharity.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [charitableWorkArea.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? ['Kent', 'Hampshire']:[],
  [lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '125099':'',
  [hasYearPublishedAccounts.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? true:false,
  [hasYoungPeople.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? true:false,
}; 
