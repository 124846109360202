import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../styles';


export default function Stopper(props) {
  const classes = useStyles();
  
  const introText = props.init && props.init.formTexts.WebinarIntro;

  return (
    <div className={classes.stopper}>
      <Grid container spacing={6} style={{background:`url(${props.currentCycle && props.currentCycle.bannerUrl}) no-repeat center 80%`,width: '-webkit-fill-available','background-size': 'cover','-moz-background-size': 'cover', '-webkit-background-size': 'cover'}}>
        <Grid xs={false} sm={8} className={classes.stopperLeft}>
         {/*  <img src={props.currentCycle && props.currentCycle.bannerUrl}/> */}
        </Grid>
        <Grid xs={12} sm={4}>
          <div className={classes.stopperRight} style={{ backgroundColor: '#fff', padding: 12, margin: '24px', opacity: 0.92 }}>
            <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
              {props.currentCycle && props.currentCycle.title}
            </Typography>
            <br/>
            <Typography component="h6" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
              {props.currentCycle && new Date(props.currentCycle.start).toLocaleDateString("en-GB", {day: "numeric",month: "short",year: "numeric"})}
            </Typography>
            <br/>
            <Typography component="div" variant="" className={classes.webinarIntro} color="secondary" gutterBottom>
              <div dangerouslySetInnerHTML={{ __html: introText }}></div>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}