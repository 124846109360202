import * as Yup from 'yup';
import moment from 'moment';
import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    charitableWorkArea,
    workPriorities,
    lastFYTurnover,
    hasYearPublishedAccounts,
    hasYoungPeople,
  }
} = eligibilityCheckFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const currencyRegExp = /^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;

export default [
  Yup.object().shape({
    [charitableWorkArea.name]: Yup.array().min(1, `${charitableWorkArea.requiredErrorMsg}`),
   /*  [workPriorities.name]: Yup.string().required(`${workPriorities.requiredErrorMsg}`), */
   // [workPriorities.name]: Yup.array().min(1, `${workPriorities.requiredErrorMsg}`),
   [lastFYTurnover.name]: Yup.string()
      .required(`${lastFYTurnover.requiredErrorMsg}`)
      .matches(currencyRegExp, `Invalid ${lastFYTurnover.label}!`)
  })
 /*   Yup.object().shape({
   /*[address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
     [city.name]: Yup.string()
      .nullable()
      .required(`${city.requiredErrorMsg}`),
    [zipcode.name]: Yup.string()
      .required(`${zipcode.requiredErrorMsg}`)
      .test(
        'len',
        `${zipcode.invalidErrorMsg}`,
        val => val && val.length === 5
      ),
    [country.name]: Yup.string()
      .nullable()
      .required(`${country.requiredErrorMsg}`)  
    [nameOnCard.name]: Yup.string().required(`${nameOnCard.requiredErrorMsg}`),
    [cardNumber.name]: Yup.string()
      .required(`${cardNumber.requiredErrorMsg}`)
      .matches(visaRegEx, cardNumber.invalidErrorMsg),
    [cvv.name]: Yup.string()
      .required(`${cvv.requiredErrorMsg}`)
      .test('len', `${cvv.invalidErrorMsg}`, val => val && val.length === 3)
  })  */
];
