import React from 'react';
import { Grid, Typography} from '@material-ui/core';
import { InputField, SelectField, InputAdornmentField, SelectMultipleField} from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import { SelectType, TEXT_NONE_OF_THE_ABOVE } from '../../../common/DataTypes';

export default function PersonalDetails(props) {
  const classes = useStyles();

  const {
    formField: {
      salutation,
      firstName,
      lastName,
      jobTitle,
      email,
      confirmEmail,
      phone,
      orgType,
      orgName,
      address, 
      postcode, 
      website,
      regCharityNumber, 
      regCompanyNumber,
      lastFYTurnover,
      charitableWorkArea
    },
    getChoices,
  } = props;

  const salutations = getChoices("BGSalutation") && getChoices("BGSalutation");
  const eligibleText = props.init && props.init.formTexts.EligibleText;

  const orgTypesChoices = getChoices("BGOrganisationType") && getChoices("BGOrganisationType");
  const charitableWorkAreaChoices = getChoices("BGCharitableWorkArea") && getChoices("BGCharitableWorkArea");

  return (
    <div className={classes.personaldetails}>
      <Grid item xs={12}>
        <Card className={classes.info} variant="outlined" color="primary">
          <CardContent>
              <div dangerouslySetInnerHTML={{__html:eligibleText}}></div>
          </CardContent>
        </Card>    
        </Grid>
      <br/>
        <Grid item xs={12}>
          <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
          Personal details
          </Typography>
        </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} className="selectRadio">
          <SelectField
            name={salutation.name}
            label={salutation.label}
            data={salutations}
            fullWidth
            variant="outlined"
            color="secondary"
            selectType={SelectType.RADIO}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={firstName.name} label={firstName.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={lastName.name} label={lastName.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
       
        <Grid item xs={12}>
          <InputField name={jobTitle.name} label={jobTitle.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={email.name} label={email.label} type="Email" fullWidth variant="outlined" color="secondary"/>
        </Grid>
         <Grid item xs={12} sm={6}>
          <InputField name={confirmEmail.name} label={confirmEmail.label} type="Email" fullWidth variant="outlined" color="secondary"/>
        </Grid> 
        <Grid item xs={12} sm={6}>
          <InputField name={phone.name} label={phone.label} type="text" fullWidth variant="outlined" color="secondary"/>
        </Grid>
       
        <Grid item xs={12} sm={6}>
          <InputField name={address.name} label={address.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={postcode.name} label={postcode.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={orgName.name} label={orgName.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} className="selectRadio" sm={6}>
          <SelectField
            name={orgType.name}
            label={orgType.label}
            data={orgTypesChoices}
            fullWidth
            color="secondary"
            selectType={SelectType.RADIO}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField name={website.name} label={website.label} fullWidth variant="outlined" color="secondary"/>
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputField
          name={regCharityNumber.name}
          label={regCharityNumber.label}
          fullWidth
          type="Number"
          color="secondary" variant="outlined"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputField
            name={regCompanyNumber.name}
            label={regCompanyNumber.label}
            fullWidth
            type="Number"
            color="secondary" variant="outlined"/>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectMultipleField
              name={charitableWorkArea.name}
              label={charitableWorkArea.label}
              data={charitableWorkAreaChoices.filter((item)=>item.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1)}
              value={charitableWorkArea.value}
              fullWidth
              variant="outlined"
              color="secondary"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputAdornmentField
              name={lastFYTurnover.name}
              label={lastFYTurnover.label}
              adornment="£"
              fullWidth
              type="Number"
              color="secondary" variant="outlined"
          />
        </Grid>
       
      </Grid>
    </div>
  );
}
