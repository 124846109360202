import React, {useContext, useEffect} from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import { AccordionField} from '../fields';
import { EligibilityContext } from '../../../common/EligibilityContext';

import useStyles from '../../styles';
import PersonalDetails from "./PersonalDetails";
import OrganisationDetails from "./OrganisationDetails";
import ProjectDetails from "./ProjectDetails";
import Documents from "./Documents";


export default function Review(props) {
  const classes = useStyles();

  const {eligibilityResult} = useContext(EligibilityContext);
  const { values: formValues } = useFormikContext();
  const {attachments, getField} = props;

  const fieldQ1 = props.init && getField("FR02Question01");
  const fieldQ2 = props.init && getField("FR02Question02");
  const fieldQ3 = props.init && getField("FR02Question03");
  const fieldQ4 = props.init && getField("FR02Question04");
  const fieldQ5 = props.init && getField("FR02Question05");
  const fieldQ6 = props.init && getField("FR02Question06");
  const fieldQ7 = props.init && getField("BGRequestedFunding");
  const fieldQ8 = props.init && getField("FR02Question08");
  const fieldQ9 = props.init && getField("FR02Question09");
  const fieldQ10 = props.init && getField("FR02Question10");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  //const charitableWorkArea = eligibilityResult.values.charitableWorkArea && <ul> {eligibilityResult.values.charitableWorkArea.sort().map((item)=><li>{item}</li>)}</ul>;
  const formatCurrency = (value) => {
    return `£${parseFloat(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  const precisionRound=(number, precision)=>
      {
        if (precision < 0)
        {
          let factor = Math.pow(10, precision);
          return Math.round(number * factor) / factor;
        }
        else
          return +(Math.round(Number(number + "e+" + precision)) +
            "e-" + precision);
      }

  const files = attachments && attachments.length > 0 && 
  attachments.map((f)=>
    <li>{f.name} - {precisionRound(f.size/1024/1024, 2)} MB</li>
  );
  const disclaimerText = props.init && props.init.formTexts.DisclaimerText;
  return (
    <React.Fragment>
      <div className={classes.review}>
        <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
        Review
        </Typography>
        <Grid container>
            {/* <AccordionField steps={steps} /> */}
            <PersonalDetails {...props} mode="Review"/>
            <ProjectDetails {...props} mode="Review"/>
            <OrganisationDetails {...props} mode="Review"/>
            <Documents {...props} files={files} mode="Review"/>
            <div dangerouslySetInnerHTML={{__html:disclaimerText}}></div>
        </Grid>
      </div>
    </React.Fragment>
  );
}
