import * as Yup from 'yup';
import webinarFormModel from './webinarFormModel';
const {
  formField: {
    fullname,
    company,
    email,
    eventSource,
    eventSourceOther,
  }
} = webinarFormModel;


export default [
  Yup.object().shape({}),
  Yup.object().shape({
    [fullname.name]: Yup.string()
      .required(`${fullname.requiredErrorMsg}`)
      .max(255, `${fullname.label} Your full name exceeded limited charcters!`),
    [company.name]: Yup.string()
      .max(255, `${company.label} Your Company exceeded limited charcters!`)
      .required(`${company.requiredErrorMsg}`),

    [email.name]: Yup.string()
      .required(`${email.requiredErrorMsg}`)
      .email(`Invalid email address`),
   /*  [eventSource.name]: Yup.string().required(`${eventSource.requiredErrorMsg}`),
    [eventSourceOther.name]: Yup.string()
      .when(eventSource.name, {
        is: 'Other',
        then: Yup.string().required(`This is required`)
      }), */
  }),

];
