export default {
  formId: 'securityCheckForm',
  formField: {
    startMode: {
      name: 'startMode',
      label: 'Please select one of the following options:'
    },
    withCode: {
      name: 'withCode',
      label: 'I have a code to continue my application'
    },
    newApplication: {
      name: 'newApplication',
      label: 'I\'m starting a new application'
    },
    code: {
      name: 'code',
      label: 'Enter Code *',
      requiredErrorMsg: 'Code is required',
      invalidErrorMsg: 'Invalid code, please enter valid code and try again!'
    }
  }
};
