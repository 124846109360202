import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useStyles from './styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
            color="inherit"
            aria-label="visit Berkeley Foundation"
            edge="start"
            onClick={()=>{ window.open('https://www.berkeleyfoundation.org.uk', '_blank')}}
            sx={{ mr: 2, display: { sm: 'block' } }}
          >
            <img  src="/bgfoundation-logo.png" alt="Berkeley Foundation" title='visit Berkeley Foundation'/>
          </IconButton>
      </Toolbar>
    </AppBar>
    <section className={classes.overlayContainer}>
      </section>
    </div>
  );
}
