import MaterialLayout from './components/layout/MaterialLayout';
import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FundingRunCycle } from './components/forms/FundingRunCycle';
import { UnknownFR } from './components/fields';
import { WebinarForm } from './components/forms/webinar';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    loading: {
      textAlign: 'center',
    },
    notice: {
      backgroundColor: theme.palette.primary.main,
      color: '#fff'
    },

  }),
);

function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = React.useState(0);
  const [init, setInit] = useState();
  const [config, setConfig] = useState();
  const [fieldsData, setFieldsData] = useState();
  const [initErrors, setInitErrors] = useState();
  const [cycles, setCycles] = useState();
  const [currentCycle, setCurrentCycle] = useState(null);
  const [webinar, setWebinar] = useState(null);
  const currentPath = window.location.pathname.replace('/', '');

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <div className={classes.root}>
      <MaterialLayout className={!loading && currentPath && currentPath === 'webinar' ? classes.webinar : ''}>
        {loading && <CircularProgressWithLabel loading={classes.loading} value={progress} />}
        {!loading && !currentCycle && !webinar && <UnknownFR text="<h3>Oops, Something went wrong!</h3><p>This Application Form is unavailable at the moment.</p>" />}
        {!loading && currentCycle && !currentCycle.active && <UnknownFR text={currentCycle.displayText} />}
        {!loading && currentCycle && currentCycle.active && <FundingRunCycle currentCycle={currentCycle} frname={currentPath} config={config} init={currentCycle.initData} />}
      </MaterialLayout>
    </div>
  );

  function getCycles(config) {
    console.log("Fetching:", config.ApiUrl);
    fetch(
      `${config.ApiUrl}/cycles`,
      {
        cache: "no-cache",
        headers: {
          "Authorization": `Bearer ${config.ApiToken}`
        }
      }
    ).then(response => {
      return response.json()
    }).then(result => {
      if (result && result.data) {
        setCycles(result.data);
        let current = result.data.find(cycle => cycle.url === currentPath);
        if (current) {
          setCurrentCycle(current);
        }
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
        setLoading(false);
      } else {
        setInitErrors(result.errors);
        setLoading(false);
        console.log(result.errors);
      }
    })
  }

  function getConfig() {
    let apiUrl = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api';
    console.log("Fetching config:", apiUrl);
    fetch(
      `${apiUrl}/config`,
      { cache: "no-cache" }
    ).then(response => {
      return response.json()
    }).then(result => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 50));
      if (result && result.data) {
        setConfig(result.data);
        getCycles(result.data);
      } else {
        setInitErrors(result.errors);
        console.log(result.errors);
      }
    })
  }

  function CircularProgressWithLabel(props) {
    return (
      <div className={props.loading}><Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
      </div>
    );
  }
}

export default App;
