import React, { useState , useRef, useContext, useEffect} from 'react';
import {
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import { EligibilityContext } from '../../common/EligibilityContext';
import axios from "axios";
import useStyles from '../styles';


import validationSchema from './models/validationSchema';
import formModel from './models/formModel';
import formInitialValues from './models/formInitialValues';
import {Success, PersonalDetails, OrganisationDetails, ProjectDetails, Review, Documents} from './sections';
import Errors from '../Errors';

import ReCAPTCHA from "react-google-recaptcha";

const steps = ['Personal & Organisation details ', 'About your Organisation','About the Project', 'Supporting Documents','Review and Submit'];
const { formId, formField } = formModel;
const SKIP_STEP = 3;
function renderStepContent(step, props) {
    switch (step) {
      case 0:
        return <PersonalDetails formField={formField} {...props}/>;
      case 1:
        return <OrganisationDetails formField={formField} {...props}/>;
      case 2:
          return <ProjectDetails formField={formField} {...props}/>;
      case 3:
        return <Documents formField={formField} {...props} />;
      case 4:
        return <Review {...props}/>;
      default:
        return <div>Not Found</div>;
  }
}

export default function MainForm01(props) {
  const {eligibilityResult} = useContext(EligibilityContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const recaptchaRef = useRef();
  const config = eligibilityResult.config;
  //const config = props.config;

  const [serverErrors, setServerErrors] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [skipped, setSkipped] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const getChoices = (name) => {
    //let {fields} = props.init;
    let {fields} = eligibilityResult.init;
    let field =  fields && fields.value?.filter((f)=>f['name']== name)[0];
    let choices = field && field['choice']['choices']?field['choice']['choices']:[];
    return choices;
  }

  async function submitForm(values, actions, attachments){ 
    if(recaptchaRef.current) {
      //actions.setSubmitting(true);
      setServerErrors([]);
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      let currentCycle = eligibilityResult.currentCycle;

       const postData = {...values, 
        cycle:currentCycle, 
        initData:eligibilityResult.init, 
        token, 
        isRegCharity:eligibilityResult.values.regCharity,
        attachments:attachments};
      let apiUrl= `${config.ApiUrl}/new${currentCycle.id}`;// process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? process.env.REACT_APP_API_URL:'/api'; 
      const response = await fetch(apiUrl,
                      {
                        body: JSON.stringify(postData),
                        method: "POST",
                        cache:"no-cache",
                        headers: {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization":`Bearer ${config.ApiToken}`
                        }
                    })
                    .catch(error=>{
                      setServerErrors([{message:error}]);
                      actions.setSubmitting(false);
                    });
          const result = await response.json();
          console.log('result', result); 
          if(result) {
            if(result.data) {
               uploadFiles(actions, attachments, result.data.BGApplication);
              }
            }
            else if(result.errors) {
              setServerErrors(result.errors);
              actions.setSubmitting(false);
            }else if(result.error) {
              setServerErrors([{message:result.error}]);
              actions.setSubmitting(false);
            }
          }   
      }

  function  uploadFiles(actions, attachments, appRef){
    if(skipped) {
      setActiveStep(5);
      setServerErrors([]);
      actions.setSubmitting(false);
      return;
    }
    attachments.map(attachment=>{
      const formData = new FormData();
      formData.append("file", attachment);
      formData.append("fileName", attachment.name);
      formData.append("init", eligibilityResult.init);
      formData.append("siteId", eligibilityResult.init.siteId);
      formData.append("listId", eligibilityResult.init.listId);
      formData.append("docLibId", eligibilityResult.init.docLibId);
      formData.append("appRef", appRef);
      formData.append("cycle", eligibilityResult.currentCycle.cycle);
  
      let apiUrl= config.ApiUrl;
      try {
        const response = axios.post(
          `${apiUrl}/upload`,
          formData
        );
        const result = response.json();

        if(result) {
          //dont wait, update progress?  
        }
      } catch (ex) {
        console.log(ex);
       // setServerErrors([{message:ex}]);
        //actions.setSubmitting(false);
      }
    });
    setActiveStep(5);
    setServerErrors([]);
    actions.setSubmitting(false);
  };



  function handleSubmit(values, actions) {
    if (isLastStep) {
      submitForm(values, actions, attachments);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      if(activeStep == 2) {
        setDisableNext(attachments.length == 0);
      }
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
    if(activeStep == 2) {
      setDisableNext(attachments.length == 0);
    }else {
      setDisableNext(false);
    }
  }
  
  const isStepOptional=(step)=>{
    return step === SKIP_STEP;
  };

  const handleAttachments = (array) => {
    array.forEach((item, index)=>{
      attachments.push(item);
    });
    setAttachments(attachments);
    setSkipped(false);
    setDisableNext(attachments.length == 0);
}


const onFilesRemoved = (array) => {
  setAttachments(array);
    setSkipped(false);
    setDisableNext(array.length == 0);
}

const handleSkip = () => { 
  if (!isStepOptional(activeStep)) { 
      throw new Error("You can't skip a step that isn't optional.");
  }
  setActiveStep(activeStep + 1);
  setSkipped(true);

}
  
  const formProps = {init:eligibilityResult.init, getChoices, handleAttachments, attachments, onFilesRemoved, skipped};

  return (
    <>
      <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                    {eligibilityResult.currentCycle.title}
      </Typography>
        {activeStep === steps.length ? (
          <Success {...formProps}/>
        ) : (
          <React.Fragment>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map(label => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
              ))}
            </Stepper>
            <Formik 
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                  {renderStepContent(activeStep, formProps)}

                  <div className={classes.buttons}>
                    <div className={classes.wrapper}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.buttonBack} variant="contained" color="default">
                        Back
                      </Button>
                    )}
                 {/*     {isStepOptional(activeStep) && <Button onClick={handleSkip} className={classes.buttonBack} variant="contained" color="default">
                        Skip
                      </Button> } */}
                    
                      <Button
                        disabled={disableNext}
                        type="submit"
                        variant="contained"
                        color="secondary"
                        className={classes.buttonNext}
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                  <div className="reCaptcha">
                  { activeStep == 4 && <ReCAPTCHA
                          sitekey={config.RecaptchaKey}
                          ref={recaptchaRef}
                          size="invisible"
                      />
                  }
                </div>
                </Form>
              )}
            </Formik>
            {serverErrors && serverErrors.length > 0 && <Errors errors={serverErrors} />}
        </React.Fragment>
        )}
    </>
  );
}
