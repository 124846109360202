import React, { useState } from 'react';
import { MainForm01, EligibilityCheck01, Ineligible01 } from './form1';
import { MainForm02, EligibilityCheck02, Ineligible02 } from './FR02';
import { MainForm031, EligibilityCheck03, Ineligible03, MainForm032, SecurityCheck } from './FR03';
import { EligibilityContext } from '../common/EligibilityContext';
import { FR03Stage1Context } from '../common/FR03Stage1Context';
import { UnknownFR } from '../fields';
import { WebinarForm } from '../forms/webinar';

export function FundingRunCycle(props) {
    const [eligibilityResult, setEligibilityResult] = useState({});
    const [securityCheckResult, setSecurityCheckResult] = useState({});
    const [fr03Stage1, setFR03Stage1] = useState();

    const handleEligibility = (result) => {
        setEligibilityResult(result);
    }

    const handleSecurityCheck = (result) => {
        setSecurityCheckResult(result);
        setFR03Stage1(result);
    }

    return (
        <>
            <EligibilityContext.Provider value={{ eligibilityResult, setEligibilityResult }}>
                {props.frname == "01" && !eligibilityResult.checked && props.init && <EligibilityCheck01 init={props.init} config={props.config} handleEligibility={handleEligibility} currentCycle={props.currentCycle} />}
                {props.frname == "01" && eligibilityResult.checked && props.init && !eligibilityResult.passed && <Ineligible01 currentCycle={props.currentCycle} init={props.currentCycle.initData} />}
                {props.frname == "01" && eligibilityResult.checked && props.init && eligibilityResult.passed && <MainForm01 />}
                {props.frname == "02" && !eligibilityResult.checked && props.init && <EligibilityCheck02 init={props.init} config={props.config} handleEligibility={handleEligibility} currentCycle={props.currentCycle} />}
                {props.frname == "02" && eligibilityResult.checked && props.init && !eligibilityResult.passed && <Ineligible02 currentCycle={props.currentCycle} init={props.currentCycle.initData} />}
                {props.frname == "02" && eligibilityResult.checked && props.init && eligibilityResult.passed && <MainForm02 />}

                {/* {props.frname == "03" && !eligibilityResult.checked && props.init && !props.stage && <EligibilityCheck03 init={props.init} config={props.config} handleEligibility = {handleEligibility} currentCycle={props.currentCycle}/>} */}
                {props.frname == "03" && !eligibilityResult.checked && props.init && fr03Stage1 && !fr03Stage1.stage && <EligibilityCheck03 init={props.init} config={props.config} handleEligibility={handleEligibility} currentCycle={props.currentCycle} />}
                {props.frname == "03" && eligibilityResult.checked && props.init && !props.stage && !eligibilityResult.passed && <Ineligible03 currentCycle={props.currentCycle} init={props.currentCycle.initData} />}
                {props.frname == "03" && eligibilityResult.checked && props.init && !props.stage && eligibilityResult.passed && <MainForm031 />}
            </EligibilityContext.Provider>
            {fr03Stage1 && <FR03Stage1Context.Provider value={{ fr03Stage1, setFR03Stage1 }}>
                {fr03Stage1.init && fr03Stage1.stage && (fr03Stage1.stage.StageStatus == 'Stage 2 Not Started' || fr03Stage1.stage.StageStatus == 'Stage 2 Draft') && <MainForm032 />}
                {fr03Stage1.init && fr03Stage1.stage && (fr03Stage1.stage.StageStatus == 'Stage 2 Submitted' || fr03Stage1.stage.StageStatus == 'Stage 2 Shortlisted') && <UnknownFR text={props.init.formTexts.StageExpired} />}
            </FR03Stage1Context.Provider>}
            {props.frname == "03" && !fr03Stage1 && props.init && <SecurityCheck init={props.init} config={props.config} handleSecurityCheck={handleSecurityCheck} currentCycle={props.currentCycle} />}
            {props.frname.indexOf("webinar") > -1 && props.init && <WebinarForm init={props.init} config={props.config} currentCycle={props.currentCycle} />}

        </>
    )
}