export default {
  formId: 'eligibilityCheckForm',
  formField: {
    regCharity: {
      name: 'regCharity',
      label: 'Are you a registered charity or community interest company (CIC)?'
    },
    charitableWorkArea: {
      name: 'charitableWorkArea',
      label: 'Please select the areas you operate in*',
      requiredErrorMsg: 'Please select the areas you operate in is required'
    },
    workPriorities: {
      name: 'workPriorities',
      label: 'Which of the following priorities does your main work align to?*',
      requiredErrorMsg: 'Which of the following priorities.. is required'
    },
    lastFYTurnover: {
      name: 'lastFYTurnover',
      label: 'Total income for the last financial year (£)*',
      requiredErrorMsg: 'Total income for the last.. is required'
    },
    hasYearPublishedAccounts: {
      name: 'hasYearPublishedAccounts',
      label: 'Does your organisation have at least one year of published accounts?',
      requiredErrorMsg: 'You need to have at least.. is required'
    },
    hasYoungPeople: {
      name: 'hasYoungPeople',
      label: 'Does your work enable young people, aged 16-25, to overcome barriers to work and kick-start their careers?',
      requiredErrorMsg: 'Does your work enable young people.. is required'
    },
  }
};
