import * as Yup from 'yup';
import moment from 'moment';
import securityCheckFormModel from './securityCheckFormModel';
const {
  formField: {
    withCode,
    code,
    newApplication,
    startMode
  }
} = securityCheckFormModel;


export default [
  Yup.object().shape({
  })
];
