import React, {useEffect} from 'react';
import { Typography, Grid } from '@material-ui/core';

import useStyles from '../../styles';
import AccessibilityDetails from "./AccessibilityDetails";
import PersonalDetails from "./PersonalDetails";
import DiversityDetails from './DiversityDetails';


export default function Review(props) {
  const classes = useStyles();
  const {attachments} = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const precisionRound=(number, precision)=>
      {
        if (precision < 0)
        {
          let factor = Math.pow(10, precision);
          return Math.round(number * factor) / factor;
        }
        else
          return +(Math.round(Number(number + "e+" + precision)) +
            "e-" + precision);
      }

  const files = attachments && attachments.length > 0 && 
  attachments.map((f)=>
    <li>{f.name} - {precisionRound(f.size/1024/1024, 2)} MB</li>
  );
  const disclaimerText = props.init && props.init.formTexts.DisclaimerText;
  return (
    <React.Fragment>
      <div className={classes.review}>
        <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
        {props.steps[props.stepId]}
        </Typography>
        <Grid container>
            <AccessibilityDetails {...props} mode="Review" stepId={0} editable={true}/>
            <PersonalDetails {...props} mode="Review" stepId={1} editable={true}/>
            <DiversityDetails {...props} mode="Review" stepId={2} editable={true}/>
            <div dangerouslySetInnerHTML={{__html:disclaimerText}}></div>
        </Grid>
      </div>
    </React.Fragment>
  );
}
