import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../../styles';
import { InputField, SelectField, InputAdornmentField, SelectMultipleField, InputRichTextField } from '../../../fields';
import { useFormikContext, useFormik } from 'formik';
import WarningIcon from '@material-ui/icons/Warning';
import { SelectType } from '../../../common/DataTypes';


export default function QuestionsForm(props) {
  const classes = useStyles();
  const introText = props.init && props.init.formTexts.WebinarIntro;
  const [errors, setErrors] = useState();
  const [questions, setQuestions] = useState();

  const {
    getField,
    getChoices,
    formField: {
      fullname,
      company,
      email,
      comments,
      eventSource,
      eventSourceOther
    } } = props;

  const fieldName = props.init && getField("Title");
  const fieldCompany = props.init && getField("Company");
  const fieldEmail = props.init && getField("BGEmail");
  const fieldComments = props.init && getField("BGComments");
  //const fieldEventSource = props.init && getField("BGEventSource");
  const { values: formValues } = useFormikContext();
  const { errors: formErrors, touched: formTouched } = useFormikContext();
  const disclaimerText = props.init && props.init.formTexts.WebinarDisclaimer;
  //const eventSourceChoices = getChoices("BGEventSource") && getChoices("BGEventSource");

  useEffect(() => {
    getErrors()
  }, [formTouched]);

  const getErrors = () => {
    let count = 0;
    let question = '';
    if (formErrors.fullname) {
      count++;
      question += fieldName.description;
    }
    if (formErrors.company) {
      if (count > 0) {
        question += ', '
      }
      question += fieldCompany.description;
      count++;
    }
    if (formErrors.email) {
      if (count > 0) {
        question += ', '
      }
      question += fieldEmail.description;
      count++;
    }
    /* if (formErrors.eventSource) {
      if (count > 0) {
        question += ', '
      }
      question += fieldEventSource.description;
      count++;
    }
    if (formErrors.eventSourceOther) {
      if (count > 0) {
        question += ', '
      }
      question += eventSourceOther.label;
      count++;
    } */
    setErrors(count);
    setQuestions(question);
  }
  return (
    <div className={classes.questionsform}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div><span className={classes.error}>*</span> <span style={{ color: '#0000008a' }}>Required</span></div>
        </Grid>

        <Grid item xs={12}>
          <InputField name={fullname.name} label={`${fullname.label} ${fieldName.description} *`} fullWidth variant="outlined" color="secondary" />
        </Grid>
        <Grid item xs={12}>
          <InputField name={company.name} label={`${company.label} ${fieldCompany.description} *`} fullWidth variant="outlined" color="secondary" />
        </Grid>
        <Grid item xs={12}>
          <InputField name={email.name} label={`${email.label} ${fieldEmail.description} *`} fullWidth variant="outlined" color="secondary" />
        </Grid>
        {/* <Grid item xs={formValues.eventSource && formValues.eventSource == 'Other' ? 6: 12}>
          <SelectField
            name={eventSource.name}
            label={`${eventSource.label} ${fieldEventSource.description} *`}
            data={eventSourceChoices}
            fullWidth
            color="secondary"
            selectType={SelectType.RADIO}
          />
        </Grid> */}
        {formValues.eventSource && formValues.eventSource == 'Other' && <Grid item xs={6} sm={6}>
          <InputField multiline rows={2} required={formValues.eventSource == 'Other'} name={eventSourceOther.name} label={eventSourceOther.label} errorText={eventSourceOther.requiredErrorMsg} error={formValues.eventSource == 'Other' && !formValues.eventSourceOther} fullWidth variant="outlined" color="secondary" />
        </Grid>}
        <Grid item xs={12}>
          <InputRichTextField
            name={comments.name}
            label={`${comments.label} ${fieldComments.description}`}
            fullWidth
            color="secondary" variant="outlined"
            quillClass={classes.question5Webinar} />
        </Grid>
        <Grid item xs={12}>
          {errors > 0 && <div className={classes.error}><WarningIcon style={{ verticalAlign: 'bottom' }} /> {errors} question{errors > 1 ? 's' : ''} need to be completed before submitting: {questions}</div>}
        </Grid>
        <Grid item xs={12}>
          <div dangerouslySetInnerHTML={{ __html: disclaimerText }}></div>
        </Grid> 
      </Grid>
    </div>
  )
}