import React, {useContext, useEffect} from 'react';
import { useFormikContext } from 'formik';
import { Typography, Grid } from '@material-ui/core';
import { AccordionField} from '../../../fields';
import { EligibilityContext } from '../../../common/EligibilityContext';

import useStyles from '../../styles';

export default function Review(props) {
  const classes = useStyles();

  const {eligibilityResult} = useContext(EligibilityContext);
  const { values: formValues } = useFormikContext();
  const {attachments} = props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  //const charitableWorkArea = eligibilityResult.values.charitableWorkArea && <ul> {eligibilityResult.values.charitableWorkArea.sort().map((item)=><li>{item}</li>)}</ul>;
  const formatCurrency = (value) => {
    return `£${parseFloat(value).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  const precisionRound=(number, precision)=>
      {
        if (precision < 0)
        {
          let factor = Math.pow(10, precision);
          return Math.round(number * factor) / factor;
        }
        else
          return +(Math.round(Number(number + "e+" + precision)) +
            "e-" + precision);
      }

  const files = attachments && attachments.length > 0 && 
  attachments.map((f)=>
    <li>{f.name} - {precisionRound(f.size/1024/1024, 2)} MB</li>
  );
  const steps = [
                  {title:"Personal & Organisation details",
                    skip:false,
                    values:{"1. Saluation": formValues.salutation, 
                            "2. First Name":formValues.firstName, 
                            "3. Last Name":formValues.lastName, 
                            "4. Job Title":formValues.jobTitle, 
                            "5. Email Address":formValues.email, 
                            "6. Phone Number":formValues.phone,
                            "7. Address":formValues.address, 
                            "8. Postcode":formValues.postcode, 
                            "9. Organisation Name":formValues.orgName, 
                            "10. Organisation Type": formValues.orgType, 
                            "11. Website":formValues.website,
                            "12. Registered Charity Number": formValues.regCharityNumber, 
                            "13. Registered Company Number": formValues.regCompanyNumber, 
                            "14. Where do you deliver your charitable work?": formValues.charitableWorkArea.join(', '), 
                            "15. What was your total income in the previous financial year(£)?": `£${formValues.lastFYTurnover.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`, 
                          }
                    },
                   {title:"About your Organisation", 
                    values:{
                            "16. Please describe your organisation – tell us about who you are, what you do and what your purpose is.": formValues.orgPurposeVisionValues, 
                            "17. Who does your organisation work with? Who are the young people and communities you support?":formValues.orgWorkWith, 
                            "18. What is the overall impact of your organisation?": formValues.orgImpact, 
                            "19. What work do you do to support young people on their journey to employment?":formValues.orgSupportYoungPeople, 
                            "20. What percentage of your overall activity is focused on supporting young people on their journey to employment?":formValues.percentYoungPeopleEmployment, 
                            "21. How are young people involved in the design and delivery of your work?":formValues.orgYoungPeopleWork,
                            "22. How do young people and the communities you serve contribute to the leadership, direction or strategy of your organisation?": formValues.orgGovernanceArrangements
                            }
                  },
                  {title:"About the Project", 
                  values:{
                          "23. What are you requesting funding for?": formValues.purposeOfFunding, 
                          "24. What organisational need are you trying to address?":formValues.orgFutureResilient, 
                          "25. What is the intended impact of this work for your organisation?": formValues.orgLinkStrategicPlans, 
                          "26. What is the intended impact of this work for the young people and communities you serve?": formValues.sustainableChange, 
                          "27. How will you measure the impact of your grant?": formValues.orgMesaureImpactGrant,
                          "28. What is the total cost of the project?":`£${formValues.projectCost.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`, 
                          "29. We are making grants of up to £30,000 over two years. How much would you like to apply for?":`£${formValues.requestedFunding.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
                          "30. How will you cover any shortfall?": formValues.orgCoverShortfall,
                          "31. Sharing what we learn through our work in order to influence wider policy and practice has been a key focus of the Foundation. A learning programme, consisting of at least two workshops a year and biannual one to one calls with our Grants Managers, will provide space for our partners to share, reflect and develop their work. Do you have the capacity to be part of the collective learning from the programme?": formValues.orgCommitmentLearning,
                          "32. In what other ways, beyond funding, could the Berkeley Foundation and wider Berkeley Group support your organisation? ": formValues.orgBerkeleyGroupSupportYourOrg
                          }
                },
                 {title:"Supporting Documents",
                    values: {
                      "": files
                    },
                    skip:props.skipped
                  }
                ]
  const disclaimerText = props.init && props.init.formTexts.DisclaimerText;

  return (
    <React.Fragment>
      <div className={classes.review}>
        <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
        Review
        </Typography>
        <Grid container>
          <AccordionField steps={steps} />
              <div dangerouslySetInnerHTML={{__html:disclaimerText}}></div>
        </Grid>
      </div>
    </React.Fragment>
  );
}
