import * as Yup from 'yup';
import moment from 'moment';
import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    lastFYTurnover,
    eligibility03,
    charitableWorkArea,
    eligibility05,
    eligibility06,
    eligibility07,
    eligibility08,
    eligibility09,
    eligibility10,
  }
} = eligibilityCheckFormModel;

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const currencyRegExp = /^\$?[0-9][0-9,]*[0-9]\.?[0-9]{0,2}$/i;

export default [
  Yup.object().shape({
    [charitableWorkArea.name]: Yup.array().min(1, `${charitableWorkArea.requiredErrorMsg}`),
    [lastFYTurnover.name]: Yup.number()
    .required(`${lastFYTurnover.requiredErrorMsg}`)
  })
];
