import React from 'react'
import {
    Typography,
    Grid
  } from '@material-ui/core';

import useStyles from './styles';

function Ineligible(props) {
    const {errors} = props;
    const classes = useStyles();


    return (
        <div>
            <Grid container spacing={3} >
                <Grid item xs={12}>
                        <Typography variant="h6" color="primary">
                            Something went wrong... please try again!
                        </Typography>
                        <Typography variant="p" color="primary">
                            {errors && errors.map(error=>
                                <p>{error.message}</p>
                            )}                                
                        </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default Ineligible
