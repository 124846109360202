import React from 'react';
import { Grid, Typography, Link, Button, IconButton, FormHelperText } from '@material-ui/core';
import { InputRichTextField, SelectMultipleField, CheckboxField, InputField } from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import makeStyles from '../fields/styles';
import { SelectType, TEXT_PREFER_NOT_TO_SAY } from '../../../common/DataTypes';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import { FormMode } from '../models/FormMode';

export default function DiversityDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  const {
    editable,
    mode,
    getChoices,
    getField,
    formField: {
      div1,
      div2,
      div3,
      div4,
      div5,
      div5txt
    }
  } = props;
  const { values: formValues } = useFormikContext();
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>{props.steps[props.stepId]}</Typography>;

  const fieldDiv1 = props.init && getField("FRQuestion05");
  const fieldDiv2 = props.init && getField("FRQuestion06");
  const fieldDiv3 = props.init && getField("FRQuestion07");
  const fieldDiv4 = props.init && getField("FRQuestion08");
  const fieldDiv5 = props.init && getField("FRQuestion09");
  const fieldDiv5txt = props.init && getField("FRQuestion09txt");

  const diversityIntro = props.init && props.init.formTexts.DiversityIntro;
  const diversityQ1Desc = props.init && props.init.formTexts.DiversityQ1;
  const diversityQ2Desc = props.init && props.init.formTexts.DiversityQ2;
  const diversityQ3Desc = props.init && props.init.formTexts.DiversityQ3;
  const diversityQ4Desc = props.init && props.init.formTexts.DiversityQ4;
  const diversityQ5Desc = props.init && props.init.formTexts.DiversityQ5;
  const diversityQ5TxtDesc = props.init && props.init.formTexts.DiversityQ5Txt;

  const div1Choices = props.init && getChoices("FRQuestion05") && getChoices("FRQuestion05");
  const div3Choices = props.init && getChoices("FRQuestion07") && getChoices("FRQuestion07");

  const onEdit = (step) => {
    if (props.onEdit) {
      props.onEdit(step);
    }
  }

  const modules = {
    toolbar: null
  };

  const edit = mode == FormMode.Review && editable && <IconButton aria-label="edit" onClick={() => onEdit(props.stepId)} className={classes.buttonEdit}><EditIcon /></IconButton>;

  return (
    <div className={classes.diversitydetails}>
      {(mode === FormMode.Review) && <Grid container>
        <Grid item xs={12}>
          <List>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="diversitydetails-content"
                id="diversitydetails-header"
              >
                {title}
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <ListItem button><ListItemText primary={`${div1.label} ${fieldDiv1.description}`} secondary={formValues.div1 && formValues.div1.length > 0 ? formValues.div1.join(',') : ''} /></ListItem>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div2.label} ${fieldDiv2.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.div2} modules={modules} /></ListItem></div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}></Grid>
                  <Grid item xs={12} sm={12}>
                    <ListItem button><ListItemText primary={`${div3.label} ${fieldDiv3.description}`} secondary={formValues.div3 && formValues.div3.length > 0 ? formValues.div3.join(',') : ''} /></ListItem>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div4.label} ${fieldDiv4.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.div4} modules={modules} /></ListItem></div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      {/* <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div5.label} ${fieldDiv5.description}`} secondary={formValues.div5 ? 'Yes' : 'No'} />{formValues.div5 && <ReactQuill theme="snow" readOnly={true} value={formValues.div4} modules={modules} />}</ListItem></div> */}
                      {editable && <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div5.label} ${fieldDiv5.description}`} secondary={formValues.div5 ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.div5txt} modules={modules} /></div> : "No"} /></ListItem></div>}
                      {!editable && <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div5.label} ${fieldDiv5.description}`} secondary={formValues.div5 == 'Yes' ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.div5txt} modules={modules} /></div> : "No"} /></ListItem></div>}

                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {edit}
                  </Grid>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </List>
        </Grid>
      </Grid>}

      {(mode === FormMode.Stage) &&
        <>
          {title}
          <List>
            <Grid item xs={12} sm={12}>
              <ListItem button><ListItemText primary={`${div1.label} ${fieldDiv1.description}`} secondary={formValues.div1 && formValues.div1.length > 0 ? formValues.div1.join(',') : ''} /></ListItem>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div2.label} ${fieldDiv2.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.div2} modules={modules} /></ListItem></div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <ListItem button><ListItemText primary={`${div3.label} ${fieldDiv3.description}`} secondary={formValues.div3 && formValues.div3.length > 0 ? formValues.div3.join(',') : ''} /></ListItem>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div4.label} ${fieldDiv4.description}`} /><ReactQuill theme="snow" readOnly={true} value={formValues.div4} modules={modules} /></ListItem></div>
            </Grid>
            <Grid item xs={12} sm={12}>
              {editable && <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div5.label} ${fieldDiv5.description}`} secondary={formValues.div5 ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.div5txt} modules={modules} /></div> : "No"} /></ListItem></div>}
              {!editable && <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${div5.label} ${fieldDiv5.description}`} secondary={formValues.div5 == 'Yes' ? <div><span>Yes</span><ReactQuill theme="snow" readOnly={true} value={formValues.div5txt} modules={modules} /></div> : "No"} /></ListItem></div>}

            </Grid>
          </List>
        </>
      }
      {(mode === FormMode.Edit) && <Grid container spacing={3}>
        <Grid item xs={12}>
          {title}
          <div dangerouslySetInnerHTML={{ __html: diversityIntro }}></div>
        </Grid>
        <Grid item xs={12}>
          <SelectMultipleField
            name={div1.name}
            label={`${div1.label} ${fieldDiv1.description}`}
            data={div1Choices}
            value={div1.value}
            fullWidth
            filterOption={TEXT_PREFER_NOT_TO_SAY}
            variant="outlined"
            color="secondary"
            description={diversityQ1Desc}
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={div2.name}
            label={`${div2.label} ${fieldDiv2.description}`}
            fullWidth
            color="secondary" variant="outlined"
            labelClass={classesFields.labelMultiline}
            quillClass={classesFields.div2}
            description={diversityQ2Desc}
            descriptionStyle={{ paddingLeft: 12, color: '#0000008a' }}

          />
        </Grid>
        <Grid item xs={12}>
          <SelectMultipleField
            name={div3.name}
            label={`${div3.label} ${fieldDiv3.description}`}
            data={div3Choices}
            value={div3.value}
            filterOption={TEXT_PREFER_NOT_TO_SAY}
            fullWidth
            variant="outlined"
            color="secondary"
            description={diversityQ3Desc}
          //labelStyle={{ lineHeight: '1.6em' }} 
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={div4.name}
            label={`${div4.label} ${fieldDiv4.description}`}
            fullWidth
            color="secondary" variant="outlined"
            labelClass={classesFields.labelMultiline}
            quillClass={classesFields.div4}
            description={diversityQ4Desc}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckboxField labelClass={'toggleQ5'} description={diversityQ5Desc} name={div5.name} label={`${div5.label} ${fieldDiv5.description} *`} fullWidth variant="outlined" color="secondary" labelPlacement="start" />
        </Grid>
        {formValues.div5 && <Grid item xs={12} sm={12}>
          <InputRichTextField
                name={div5txt.name}
                label={`If yes, please describe`}
                fullWidth
                color="secondary" variant="outlined"
                description={diversityQ5TxtDesc}
                required={formValues.div5.value}
              />
        </Grid>}
      </Grid>}
    </div>
  )
}