import formModel from './formModel';
const {
  formField: {
    salutation,
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    //organisationIDNumber,
    regCharityNumber, 
    regCompanyNumber, 
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    orgPurposeVisionValues,
    orgWorkWith,
    sustainableChange,
    percentYoungPeopleEmployment,
    orgImpact,
    orgYoungPeopleWork,
    orgGovernanceArrangements,
    purposeOfFunding, 
    orgFutureResilient, 
    orgLinkStrategicPlans, 
    orgSupportYoungPeople, 
    projectCost, 
    requestedFunding, 
    orgCoverShortfall,
    orgMesaureImpactGrant, 
    orgCommitmentLearning, 
    orgBerkeleyGroupSupportYourOrg,
    safeguardingPolicy
  }
} = formModel;
 
export default {
  [salutation.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Mr':'',
  [firstName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'B':'',
  [lastName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'A':'',
  [jobTitle.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'D':'',
  [email.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'U@u.com':'',
  [confirmEmail.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'U@u.com':'',
  [phone.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '07809999999':'',
  [orgName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'O':'',
  [address.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'T':'',
  [postcode.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'SW1 5KL':'',
  [website.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'https://www.bg.co.uk':'',
  [regCharityNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'123433':'',
  [regCompanyNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'23456':'',
  //[organisationIDNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '123456':'',
  [orgType.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Charity':'',
  [lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '34567':'',
  [charitableWorkArea.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? ['Kent', 'Hampshire']:[],

  [orgPurposeVisionValues.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgWorkWith.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [sustainableChange.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [percentYoungPeopleEmployment.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgImpact.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgYoungPeopleWork.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgGovernanceArrangements.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',

  [purposeOfFunding.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':null,
  [orgFutureResilient.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgLinkStrategicPlans.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgSupportYoungPeople.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [projectCost.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '':'',
  [requestedFunding.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '':'',
  [orgCoverShortfall.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgMesaureImpactGrant.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgCommitmentLearning.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? 'Lorem ipsum':'',
  [orgBerkeleyGroupSupportYourOrg.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?'BGrgBerkeleyGroupSupportYourOrg':'',
  [safeguardingPolicy.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?false:false
}; 

/*

 export default {
  [salutation.name]:'',
  [firstName.name]: '',
  [lastName.name]: '',
  [jobTitle.name]: '',
  [email.name]: '',
  [confirmEmail.name]: '',
  [phone.name]: '',
  [orgName.name]: '',
  [address.name]: '',
  [postcode.name]: '',
  [website.name]: '',
  [regCharityNumber.name]:'',
  [regCompanyNumber.name]:'',
  [orgType.name]: '',
  [lastFYTurnover.name]: '',
  [orgPurposeVisionValues.name]: '',
  [orgWorkWith.name]: '',
  [sustainableChange.name]: '',
  [percentYoungPeopleEmployment.name]: '',
  [orgImpact.name]: '',
  [orgYoungPeopleWork.name]: '',
  [orgGovernanceArrangements.name]: '',
  [purposeOfFunding.name]: '',
  [orgFutureResilient.name]: '',
  [orgLinkStrategicPlans.name]: '',
  [orgSupportYoungPeople.name]: '',
  [projectCost.name]: '',
  [requestedFunding.name]: '',
  [orgCoverShortfall.name]: '',
  [orgMesaureImpactGrant.name]: '',
  [orgCommitmentLearning.name]: '',
  [orgBerkeleyGroupSupportYourOrg.name]:''
}; 
*/


