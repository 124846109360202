import formModel from './formModel';
const {
  formField: {
    language,
    format,
    consider,
    adjustments,
    adjustmentsTxt,
    salutation,
    firstName,
    lastName,
    jobTitle,
    email,
    confirmEmail,
    phone,
    orgName,
    address,
    postcode,
    website,
    //organisationIDNumber,
    regCharityNumber,
    regCompanyNumber,
    orgType,
    lastFYTurnover,
    charitableWorkArea,
    q1,
    q2,
    q3,
    q4,
    q5,
    q6,
    q7,
    q8,
    q9,
    q10,
    q11,
    div1,
    div2,
    div3,
    div4,
    div5,
    div5txt,
  }
} = formModel;

const DEVTEXT = 'development';

export default {
  [language.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [format.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [consider.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [adjustments.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [adjustmentsTxt.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem Ipsum' : '',
  [salutation.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Mr' : '',
  [firstName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'B' : '',
  [lastName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'A' : '',
  [jobTitle.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'D' : '',
  [email.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'U@u.com' : '',
  [confirmEmail.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'U@u.com' : '',
  [phone.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '07809999999' : '',
  [orgName.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'O' : '',
  [address.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'T' : '',
  [postcode.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'SW1 5KL' : '',
  [website.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'https://www.bg.co.uk' : '',
  [regCharityNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '123433' : '',
  [regCompanyNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '23456' : '',
  //[organisationIDNumber.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT? '123456':'',
  [orgType.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Charity' : '',
  [lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '34567' : '',
  [charitableWorkArea.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Kent', 'Hampshire'] : [],

  [div1.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Faith communities'] : [],
  [div2.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '' : '',
  [div3.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Faith communities'] : [],
  [div4.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '' : '',
  [div5.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? true : false,
  [div5txt.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  

  [q1.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q2.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q3.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Operations that are fit for purpose', 'People and Culture'] : [],
  [q4.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q5.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem ipsum' : '',
  [q6.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '505050' : '',
  [q7.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '22222' : '',

  [q8.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '16. Lorem ipsum' : '',
  [q9.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '17. Lorem ipsum' : '',
  [q10.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? ['Effective leadership'] : [],
  [q11.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? '19. Lorem ipsum' : '',
}; 