import React, { useEffect} from 'react';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { InputRichTextField, CheckboxField} from '../../../fields';
import useStyles from '../../styles';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';
import makeStyles from '../fields/styles';

export default function OrganisationDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    formField: {q8, q9, q10, q11}, getField, mode} = props;
    const { values: formValues } = useFormikContext();
    const fieldQ8 = props.init && getField("FR02Question08");
    const fieldQ9 = props.init && getField("FR02Question09");
    const fieldQ10 = props.init && getField("FR02Question10");
    const fieldQ11 = props.init && getField("FR02Question11");
    const modules = {
      toolbar: null
    };
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom> About your organisation</Typography>;
  const aboutOrg = props.init && props.init.formTexts.AboutOrg;
  
  const onEdit = (step) => {
    if(props.onEdit) {
      props.onEdit(step);
    }
   }
  const edit = mode == "Review" && <IconButton aria-label="edit" onClick={()=>onEdit(2)} className={classes.buttonEdit}><EditIcon /></IconButton>;

  return (
    <div className={classes.aboutOrg}>
      {mode == "Review" && 
        <List><Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
                {title}
            </AccordionSummary> 
            <AccordionDetails>
            <Grid container>
              <Grid item xs={12}>
                <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q8.label} ${fieldQ8.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q8} modules={modules}/></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                  <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q9.label} ${fieldQ9.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q9} modules={modules}/></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                  <div className={classesFields.reviewRichText}><ListItem button><ListItemText primary={`${q11.label} ${fieldQ11.description}`}/><ReactQuill theme="snow" readOnly={true} value={formValues.q11} modules={modules}/></ListItem></div>
              </Grid>
              <Grid item xs={12}>
                  <ListItem button><ListItemText primary={`${q10.label} ${fieldQ10.description}`} secondary={formValues.q10?"Yes":"No"}/></ListItem>
              </Grid>
              <Grid item xs={12} sm={12}>
                    {edit}
                  </Grid>
            </Grid>
            </AccordionDetails>
      </Accordion></List>
      }
      {mode == "Edit" && <>
        {title}
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <div dangerouslySetInnerHTML={{__html:aboutOrg}}></div>
            </Grid>
          <Grid item xs={12}>
            <InputRichTextField
                name={q8.name}
                label={`${q8.label} ${fieldQ8.description} *`}
                fullWidth
                color="secondary" variant="outlined"
              />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
              name={q9.name}
              label={`${q9.label} ${fieldQ9.description} *`}
              labelClass= {classesFields.labelMultiline}
              quillClass = {classesFields.q9}
              fullWidth
              color="secondary" variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <InputRichTextField
                  name={q11.name}
                  labelClass= {classesFields.labelMultiline}
                  quillClass = {classesFields.q11}
                  label={`${q11.label} ${fieldQ11.description} *`}
                  fullWidth
                  color="secondary" variant="outlined"
                />
          </Grid>
          <Grid item xs={12}>
            <CheckboxField className={classes.toggleText} name={q10.name} label={`${q10.label} ${fieldQ10.description}`} fullWidth variant="outlined" color="secondary" labelPlacement="start"/> 
          </Grid>
        </Grid>
        </>}
    </div>
  );
}
