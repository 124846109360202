import eligibilityCheckFormModel from './eligibilityCheckFormModel';
const {
  formField: {
    regCharity,
    lastFYTurnover,
    eligibility03,
    charitableWorkArea,
    eligibility05,
    eligibility06,
    eligibility07,
    eligibility08,
    eligibility09,
    eligibility10,
  }
} = eligibilityCheckFormModel;

 export default {
  [regCharity.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [lastFYTurnover.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? '125099':'',
  [eligibility03.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [charitableWorkArea.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'? ['Kent', 'Hampshire']:[],
  [eligibility05.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [eligibility06.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [eligibility07.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [eligibility08.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [eligibility09.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
  [eligibility10.name]:process.env.REACT_APP_API_URL && process.env.NODE_ENV === 'development'?true:false,
}; 
