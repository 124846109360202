export default {
  formId: 'eligibilityCheckForm',
  formField: {
    regCharity: {
      name: 'regCharity',
      label: 'Are you a registered charity or community interest company (CIC)? *'
    },
    lastFYTurnover: {
      name: 'lastFYTurnover',
      label: 'What was your total published income for the last financial year (£) *',
      requiredErrorMsg: 'What was your total published income.. is required'
    },
    eligibility03: {
      name: 'eligibility03',
      label: 'Does your organisation have at least one year of published accounts? *'
    },
    charitableWorkArea: {
      name: 'charitableWorkArea',
      label: 'Please select the areas you operate in *',
      requiredErrorMsg: 'Please select the areas you operate in is required'
    },
    eligibility05: {
      name: 'eligibility05',
      label: 'Is the work of your organisation targeted at young people from Black and Minoritised communities?* (by targeted we mean more than 50% of your organisation’s beneficiaries) *'
    },
    eligibility06: {
      name: 'eligibility06',
      label: 'Do you deliver services to improve the mental health and wellbeing of young people from Black and Minoritised communities? *'
    },
    eligibility07: {
      name: 'eligibility07',
      label: 'Are you applying for funding towards organisational development work? *'
    },
    eligibility08: {
      name: 'eligibility08',
      label: 'Are you applying for funding over two years? *'
    },
    eligibility09: {
      name: 'eligibility09',
      label: 'Are you requesting no more than 20% of your overall turnover in any one year? *'
    },
    eligibility10: {
      name: 'eligibility10',
      label: 'Does your organisation have a safeguarding policy? *'
    }
  }
};
