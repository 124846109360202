export default {
  formId: 'mainForm',
  formField: {
    salutation: {
      name: 'salutation',
      label: '1. Salutation'
    },
    firstName: {
      name: 'firstName',
      label: '2. First Name*',
      requiredErrorMsg: 'This field is required'
    },
    lastName: {
      name: 'lastName',
      label: '3. Last Name*',
      requiredErrorMsg: 'This field is required'
    },
   
    jobTitle: {
      name: 'jobTitle',
      label: '4. Job Title*',
      requiredErrorMsg: 'This field is required'
    },
    email: {
      name: 'email',
      label: '5. Email Address*',
      requiredErrorMsg: 'This field is required'
    },
    confirmEmail: {
      name: 'confirmEmail',
      label: 'Confirm Email Address*',
      requiredErrorMsg: 'This field is required'
    },
    phone: {
      name: 'phone',
      label: '6. Phone Number*',
      requiredErrorMsg: 'This field is required'
    },
    address: {
      name: 'address',
      label: '7. Address*',
      requiredErrorMsg: 'This field is required'
    },
    postcode: {
      name: 'postcode',
      label: '8. Post Code*',
      requiredErrorMsg: 'This field is required'
    },
    orgName: {
      name: 'orgName',
      label: '9. Organisation Name*',
      requiredErrorMsg: 'This field is required'
    },
    orgType: {
      name: 'orgType',
      label: '10. Organisation Type*',
      requiredErrorMsg: 'This field is required'
    },
    website: {
      name: 'website',
      label: '11. Website*',
      requiredErrorMsg: 'This field is required'
    },
    organisationIDNumber: {
      name: 'organisationIDNumber',
      label: '12. Registered Charity/Company Number',
      requiredErrorMsg: 'This field is required'
    },
     regCharityNumber: {
      name: 'regCharityNumber',
      label: '12. Registered Charity Number',
      requiredErrorMsg: 'This field is required'
    },
    regCompanyNumber: {
      name: 'regCompanyNumber',
      label: '13. Registered Company Number',
      requiredErrorMsg: 'This field is required'
    },
    charitableWorkArea: {
      name: 'charitableWorkArea',
      label: '14. Where do you deliver your charitable work?*',
      requiredErrorMsg: 'This field is required'
    },
    lastFYTurnover: {
      name: 'lastFYTurnover',
      label: '15.  What was your total income in the previous financial year(£)?*',
      requiredErrorMsg: 'This field is required'
    },
    orgPurposeVisionValues: {
      name: 'orgPurposeVisionValues',
      label: '16. Please describe your organisation – tell us about who you are, what you do and what your purpose is.*',
      requiredErrorMsg: 'This field is required'
    },  
    orgWorkWith: {
      name: 'orgWorkWith',
      label: '17. Who does your organisation work with? Who are the young people and communities you support?*',
      requiredErrorMsg: 'This field is required'
    },
    orgImpact  : {
      name: 'orgImpact',
      label: '18. What is the overall impact of your organisation?*',
      requiredErrorMsg: 'This field is required'
    },
    orgSupportYoungPeople : {
      name: 'orgSupportYoungPeople',
      label: '19. What work do you do to support young people on their journey to employment?*',
      requiredErrorMsg: 'This field is required'
    },
    percentYoungPeopleEmployment  : {
      name: 'percentYoungPeopleEmployment',
      label: '20.  What percentage of your overall activity is focused on supporting young people on their journey to employment?*',
      requiredErrorMsg: 'This field is required'
    },
   
    orgYoungPeopleWork : {
      name: 'orgYoungPeopleWork',
      label: '21. How are young people involved in the design and delivery of your work?*',
      requiredErrorMsg: 'This field is required'
    },
    orgGovernanceArrangements: {
      name: 'orgGovernanceArrangements',
      label: '22. How do young people and the communities you serve contribute to the leadership, direction or strategy of your organisation?',
      requiredErrorMsg: 'This field is required'
    },
    purposeOfFunding : {
      name: 'purposeOfFunding',
      label: '23. What are you requesting funding for?*',
      requiredErrorMsg: 'This field is required'
    },
    orgFutureResilient : {
      name: 'orgFutureResilient',
      label: '24. What organisational need are you trying to address?*',
      requiredErrorMsg: 'This field is required'
    },
    orgLinkStrategicPlans : {
      name: 'orgLinkStrategicPlans',
      label: '25. What is the intended impact of this work for your organisation?*',
      requiredErrorMsg: 'This field is required'
    },
    sustainableChange : {
      name: 'sustainableChange',
      label: '26. What is the intended impact of this work for the young people and communities you serve?*',
      requiredErrorMsg: 'This field is required'
    },
    orgMesaureImpactGrant : {
      name: 'orgMesaureImpactGrant',
      label: '27.How will you measure the impact of your grant?*',
      requiredErrorMsg: 'This field is required'
    },
    projectCost : {
      name: 'projectCost',
      label: '28. What is the total cost of the project?*',
      requiredErrorMsg: 'This field is required'
    },
    requestedFunding  : {
      name: 'requestedFunding',
      label: '29. We are making grants of up to £30,000 over two years. How much would you like to apply for?*',
      requiredErrorMsg: 'This field is required'
    },
    orgCoverShortfall : {
      name: 'orgCoverShortfall',
      label: '30. How will you cover any shortfall?*',
      requiredErrorMsg: 'This field is required'
    },
    orgCommitmentLearning  : {
      name: 'orgCommitmentLearning',
      label: '31. Sharing what we learn through our work in order to influence wider policy and practice has been a key focus of the Foundation. A learning programme, consisting of at least two workshops a year and biannual one to one calls with our Grants Managers, will provide space for our partners to share, reflect and develop their work. Do you have the capacity to be part of the collective learning from the programme? *',
      requiredErrorMsg: 'This field is required'
    },
    orgBerkeleyGroupSupportYourOrg  : {
      name: 'orgBerkeleyGroupSupportYourOrg',
      label: '32. In what other ways, beyond funding, could the Berkeley Foundation and wider Berkeley Group support your organisation?',
      requiredErrorMsg: 'This field is required'
    },
    safeguardingPolicy:{
      name: 'safeguardingPolicy',
      label: 'Do you have an up to date Safeguarding Policy?',
      requiredErrorMsg: 'This field is required'
    },
    attachments:{
      name: 'attachments',
      label: 'Supporting Documents',
      requiredErrorMsg: 'This field is required'
    },
  }
};
