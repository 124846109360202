import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
  root: {
    minWidth: 275,
    width:'100%'
  },
  multiSelect: {
    '& label':{
      color:theme.palette.secondary.main,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor:'#fff',
      paddingLeft:5,
      paddingRight:5,
      'transform': 'translate(14px, -6px) scale(0.87) !important',
      color:theme.palette.secondary.main,
    }
  },
  singleText: {
    '& label':{
      color:theme.palette.secondary.main,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      'transform': 'translate(14px, -6px) scale(0.87) !important',
      'padding-right':4,
      backgroundColor: '#fff',
      color:theme.palette.secondary.main,
    }
  },
  multiText: {
    '& label':{
      color:theme.palette.secondary.main,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      'transform': 'translate(14px, -6px) scale(0.87) !important',
      'padding-right':4,
      backgroundColor: '#fff',
      color:theme.palette.secondary.main,
      width: 'auto',
      lineHeight:'1.6em'
    },
    '& input':{
      marginTop:'1.4em'
    }
  },
  toggleText: {
    '& .MuiFormControlLabel-label':{
      color:theme.palette.secondary.main,
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  quill: {
    marginTop:0,
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },

  q4: {
    marginTop:'1.4em'
  },

  q9: {
    marginTop:'4.6em'
  },
  q11: {
    marginTop:'2.8em'
  },
  
  reviewRichText: {
    '& .ql-container': {
      'border':'0px solid #ccc',
    },
    '& .ql-editor': {
      'color':'#0000008a',
       padding:'12px 15px 12px 0'
    },
    '& .ql-editor p': {
      fontSize:14
    },
    '& .MuiButtonBase-root': {
      display:'block'
    },
  },
  subTitle: {
    /* fontSize: theme.typography.pxToRem(15), */
    fontWeight: theme.typography.fontWeightRegular,
    color:theme.palette.secondary.main,
    flexGrow: 1,
    fontFamily:'Rockwell-MT',
    borderBottom:14,
    ':after': {
      content:'test ',
      borderBottom:14,
      width:100
    }
  },
  labelMultiline: {
    fontSize: '1.4em',
    lineHeight:'1.6em',
    width: 'auto'
  },

}));
