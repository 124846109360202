import React from 'react'
import {
    Typography,
    Grid
  } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import useStyles from './styles';

function Ineligible01(props) {
    const classes = useStyles();
    const ineligibleText = props.init && props.init.formTexts.IneligibleText;

    return (
        <>
            <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                    {props.currentCycle.title}
            </Typography>
        <div class={classes.ineligibile}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card className={classes.notice} variant="outlined" color="secondary">
                        <CardContent>
                            <div dangerouslySetInnerHTML={{__html:ineligibleText}}></div>
                        </CardContent>
                    </Card>    
                </Grid>
            </Grid>
        </div>
        </>
    )
}

export default Ineligible01
