import React from 'react';
import { Grid, Typography, Link, Button, IconButton} from '@material-ui/core';
import { InputField, SelectField, InputAdornmentField, SelectMultipleField} from '../../../fields';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import useStyles from '../../styles';
import makeStyles from '../fields/styles';
import { SelectType, TEXT_NONE_OF_THE_ABOVE } from '../../../common/DataTypes';
import { useFormikContext } from 'formik';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ReactQuill from 'react-quill';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import List from '@material-ui/core/List';

export default function PersonalDetails(props) {
  const classes = useStyles();
  const classesFields = makeStyles();

  const {
    formField: {
      salutation,
      firstName,
      lastName,
      jobTitle,
      email,
      confirmEmail,
      phone,
      orgType,
      orgName,
      address, 
      postcode, 
      website,
      regCharityNumber, 
      regCompanyNumber,
      lastFYTurnover,
      charitableWorkArea
    },
    getChoices,
    getField,
    mode
  } = props;
  const { values: formValues } = useFormikContext();
  const salutations = getChoices("BGSalutation") && getChoices("BGSalutation");
  const eligibleText = props.init && props.init.formTexts.EligibleText;
  const orgTypesChoices = getChoices("BGOrganisationType") && getChoices("BGOrganisationType");
  const charitableWorkAreaChoices = getChoices("BGCharitableWorkArea") && getChoices("BGCharitableWorkArea");
  const title = <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>Personal & Organisation details</Typography>;
  const fieldSalutaion = props.init && getField("BGSalutation");
  const fieldFirstName = props.init && getField("FirstName");
  const fieldLastName = props.init && getField("BGLastName");
  const fieldJobTitle = props.init && getField("JobTitle");
  const fieldEmail = props.init && getField("BGEmail");
  const fieldPhoneNumber = props.init && getField("BGPhoneNumber");
  const fieldAddress = props.init && getField("BGAddress");
  const fieldPostalCode = props.init && getField("BGPostalCode");
  const fieldOrganisationName = props.init && getField("BGOrganisationName");
  const fieldOrganisationType = props.init && getField("BGOrganisationType");
  const fieldWebsite= props.init && getField("BGWebsite");
  const fieldRegisteredCharityNumber= props.init && getField("BGRegisteredCharityNumber");
  const fieldCharitableWorkArea= props.init && getField("BGCharitableWorkArea");
  const fieldLastFYTurnover= props.init && getField("BGLastFYTurnover");
  const fieldRegisteredCompanyNumber= props.init && getField("BGRegisteredCompanyNumber");

  const onEdit = (step) => {
    if(props.onEdit) {
      props.onEdit(step);
    }
   }
  const edit = mode == "Review" && <IconButton aria-label="edit" onClick={()=>onEdit(0)} className={classes.buttonEdit}><EditIcon /></IconButton>;
  return (
    <div className={classes.personaldetails}>
      {mode == "Review" &&
      <Grid container>
        <Grid item xs={12}> 
          <List>
          <Accordion>
             <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {title}
            </AccordionSummary> 
            <AccordionDetails>
              <Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                     <ListItem button><ListItemText primary={`${salutation.label} ${fieldSalutaion.description}`} secondary={formValues.salutation}/></ListItem>
                  </Grid>
                
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${firstName.label} ${fieldFirstName.description}`} secondary={formValues.firstName}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary={`${lastName.label} ${fieldLastName.description}`} secondary={formValues.lastName}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${jobTitle.label} ${fieldJobTitle.description}`} secondary={formValues.jobTitle}/></ListItem>
                  </Grid>
                
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary={`${email.label} ${fieldEmail.description}`} secondary={formValues.email}/></ListItem>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${phone.label} ${fieldPhoneNumber.description}`} secondary={formValues.phone}/></ListItem>
                  </Grid>
              
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary={`${address.label} ${fieldAddress.description}`} secondary={formValues.address}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${postcode.label} ${fieldPostalCode.description}`} secondary={formValues.postcode}/></ListItem>
                  </Grid>
         
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary={`${orgName.label} ${fieldOrganisationName.description}`} secondary={formValues.orgName}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${orgType.label} ${fieldOrganisationType.description}`} secondary={formValues.orgType}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${website.label} ${fieldWebsite.description}`} secondary={formValues.website}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary={`${regCharityNumber.label} ${fieldRegisteredCharityNumber.description}`} secondary={formValues.regCharityNumber}/></ListItem>
                  </Grid>
                
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${regCompanyNumber.label} ${fieldRegisteredCompanyNumber.description}`} secondary={formValues.regCompanyNumber}/></ListItem>
                  </Grid>
                  </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                     <ListItem button><ListItemText primary={`${charitableWorkArea.label} ${fieldCharitableWorkArea.description}`} secondary={formValues.charitableWorkArea.join(',')}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ListItem button><ListItemText primary={`${lastFYTurnover.label} ${fieldLastFYTurnover.description}`} secondary={'£'+formValues.lastFYTurnover.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}/></ListItem>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {edit}
                   
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
          </List>
          </Grid>
        </Grid>
      }
      {mode == "Edit" && 
        <> 
        <Grid item xs={12}>
        <Card className={classes.info} variant="outlined" color="primary">
          <CardContent>
              <div dangerouslySetInnerHTML={{__html:eligibleText}}></div>
          </CardContent>
        </Card>    
        </Grid>
        <br/>
          <Grid item xs={12}>
          {title}
          </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} className="selectRadio">
            <SelectField
              name={salutation.name}
              label={`${salutation.label} ${fieldSalutaion.description}`}
              data={salutations}
              fullWidth
              variant="outlined"
              color="secondary"
              selectType={SelectType.RADIO}
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={firstName.name} label={`${firstName.label} ${fieldFirstName.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={lastName.name} label={`${lastName.label} ${fieldLastName.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
        
          <Grid item xs={12}>
            <InputField name={jobTitle.name} label={`${jobTitle.label} ${fieldJobTitle.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={email.name} label={`${email.label} ${fieldEmail.description} *`} type="Email" fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={confirmEmail.name} label={`${confirmEmail.label} ${fieldEmail.description} *`} type="Email" fullWidth variant="outlined" color="secondary"/>
          </Grid> 
          <Grid item xs={12} sm={6}>
            <InputField name={phone.name} label={`${phone.label} ${fieldPhoneNumber.description} *`} type="text" fullWidth variant="outlined" color="secondary"/>
          </Grid>
        
          <Grid item xs={12} sm={6}>
            <InputField name={address.name} label={`${address.label} ${fieldAddress.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={postcode.name} label={`${postcode.label} ${fieldPostalCode.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={orgName.name} label={`${orgName.label} ${fieldOrganisationName.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} className="selectRadio" sm={6}>
            <SelectField
              name={orgType.name}
              label={`${orgType.label} ${fieldOrganisationType.description} *`}
              data={orgTypesChoices}
              fullWidth
              color="secondary"
              selectType={SelectType.RADIO}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField name={website.name} label={`${website.label} ${fieldWebsite.description} *`} fullWidth variant="outlined" color="secondary"/>
          </Grid>
          <Grid item xs={12} sm={6}>
          <InputField
            name={regCharityNumber.name}
            label={`${regCharityNumber.label} ${fieldRegisteredCharityNumber.description}`}
            fullWidth
            type="number"
            color="secondary" variant="outlined"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputField
              name={regCompanyNumber.name}
              label={`${regCompanyNumber.label} ${fieldRegisteredCompanyNumber.description}`}
              fullWidth
              type="number"
              color="secondary" variant="outlined"/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectMultipleField
                name={charitableWorkArea.name}
                label={`${charitableWorkArea.label} ${fieldCharitableWorkArea.description} *`}
                data={charitableWorkAreaChoices.filter((item)=>item.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1 && item.indexOf('West Midlands') == -1)}
                value={charitableWorkArea.value}
                fullWidth
                variant="outlined"
                color="secondary"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputAdornmentField
                name={lastFYTurnover.name}
                label={`${lastFYTurnover.label} ${fieldLastFYTurnover.description} *`}
                adornment="£"
                fullWidth
                type="number"
                color="secondary" variant="outlined"
            />
          </Grid>
        
        </Grid>
      </>}
    </div>
  );
}
