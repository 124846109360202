import React, { useState , useRef, useContext, createRef} from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import useStyles from '../../styles';
import './Dropzone.css'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export default function Dropzone(props) {
    const classes = useStyles();
    const [highlight, setHighlight] = useState(false);
    const fileInputRef = createRef();
    const {filesAdded} = props;

    const onDragOver = (event) => {
        event.preventDefault();
        if(props.disabled) return;
        setHighlight(true);
    }

    const onDragLeave=()=> {
        setHighlight(false);
      }

      const onDrop=(event)=> {
        event.preventDefault()
    
        if (props.disabled) return
    
        const files = event.dataTransfer.files
        if (props.onFilesAdded) {
          const array = fileListToArray(files)
          props.onFilesAdded(array)
        }
        setHighlight(false);
      }

      const openFileDialog=()=> {
        if (props.disabled) return
        fileInputRef.current.click();
      }

      const onFilesAdded=(evt) => {
        if (props.disabled) return;
        const files = evt.target.files
        if (props.onFilesAdded) {
          const array = fileListToArray(files)
          props.onFilesAdded(array);
        }
      }

      const fileListToArray=(list)=> {
        let {filesAdded} = props;
        const array = []
        for (var i = 0; i < list.length; i++) {
          let exists = filesAdded.filter(f=>f.name == list[i].name);
          if(exists.length==0 && list.item(i).size <= 60000000) {
            array.push(list.item(i))
          }
        }
        return array;
      }

      const precisionRound=(number, precision)=>
      {
        if (precision < 0)
        {
          let factor = Math.pow(10, precision);
          return Math.round(number * factor) / factor;
        }
        else
          return +(Math.round(Number(number + "e+" + precision)) +
            "e-" + precision);
      }

      const onRemoveClick = (e, file) => {
        let {filesAdded} = props;
        let removed = filesAdded.filter(f=>f.name != file.name);
        props.onFilesRemoved(removed);
      };

      const files = filesAdded && filesAdded.length > 0 && 
      filesAdded.map((f, index)=>
        <li>
          {f.name} - {precisionRound(f.size/1024/1024, 2)} MB   <IconButton color="default" aria-label="Delete" onClick={(e)=>onRemoveClick(e, f)}>
        <HighlightOffIcon />
      </IconButton>
      </li>
      );
    
    return (
        <>
           <div
            className={`Dropzone ${highlight? 'Highlight':''}`}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            //onClick={openFileDialog}
            style={{ cursor: props.disabled ? 'default' : 'pointer' }}
            >
                {filesAdded && filesAdded.length > 0 && 
                  <div style={{fontSize:'1em'}}>
                    <ol>
                        {files}
                    </ol>
                  </div>
                 }
                 <input
                    ref={fileInputRef}
                    className="FileInput"
                    type="file"
                    multiple
                    onChange={onFilesAdded}
                    accept = '.doc,.docx,.pdf'
                />

                 <div className={'icon'}>
                  <CloudUploadIcon color="secondary" />
                  <div>drag files here</div>
                  <p style={{fontSize:'.8em'}}><em>Please note an individual file size upto 50MB allowed.</em></p>
                 </div>
           </div>
          
        </>
    );
}