import React, { useEffect} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InputRichTextField} from '../../../fields';
import useStyles from '../../styles';

export default function OrganisationDetails(props) {
  const classes = useStyles();
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const {
    formField: {orgPurposeVisionValues, orgWorkWith, orgSupportYoungPeople, percentYoungPeopleEmployment, orgImpact, orgYoungPeopleWork, orgGovernanceArrangements}, getChoices  } = props;

  return (
    <React.Fragment>
      <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom>
      About your Organisation
        </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgPurposeVisionValues.name}
            label={orgPurposeVisionValues.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgWorkWith.name}
            label={orgWorkWith.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgImpact.name}
            label={orgImpact.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={orgSupportYoungPeople.name}
            label={orgSupportYoungPeople.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <InputRichTextField
            name={percentYoungPeopleEmployment.name}
            label={percentYoungPeopleEmployment.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        
        <Grid item xs={12}>
          <InputRichTextField
            name={orgYoungPeopleWork.name}
            label={orgYoungPeopleWork.label}
            fullWidth
            color="secondary" variant="outlined"
          />
        </Grid>
        
        <Grid item xs={12}>
          <InputRichTextField
            name={orgGovernanceArrangements.name}
            label={orgGovernanceArrangements.label}
            fullWidth
            color="secondary" variant="outlined"
            quillClass={orgGovernanceArrangements.name} labelClass={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
