import webinarFormModel from './webinarFormModel';
const {
  formField: {
    fullname,
    company,
    email,
    comments,
    eventSource,
    eventSourceOther,
  }
} = webinarFormModel;

const DEVTEXT = 'development1';

 export default {
  [fullname.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Puru Ramakrishna' : '',
  [company.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'BGIT' : '',
  [email.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'puru.ramakrishna@berkeleygroup.co.uk' : '',
  [eventSource.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Other' : '',
  [comments.name]: process.env.REACT_APP_API_URL && process.env.NODE_ENV === DEVTEXT ? 'Lorem Ipsum' : '',
 }

