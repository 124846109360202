import React, { useState } from 'react';
import {
  Button,
  Typography,
  CircularProgress,
  Grid
} from '@material-ui/core';
import {  FormControlLabel,  Switch} from '@material-ui/core';
import {SelectMultipleField, InputAdornmentField, CheckboxField} from '../../fields';
import useStyles from './styles';
import { Formik, Form } from 'formik';
import {TEXT_NONE_OF_THE_ABOVE} from '../../common/DataTypes';


import eligibilityCheckValidationSchema from './models/eligibilityCheckValidationSchema';
import eligibilityCheckFormModel from './models/eligibilityCheckFormModel';
import eligibilityCheckFormInitialValues from './models/eligibilityCheckFormInitialValues';
import styles from '../../header/styles';

const { formId, formField } = eligibilityCheckFormModel;

function EligibilityCheck01(props) {
    const classes = useStyles();
    const currentValidationSchema = eligibilityCheckValidationSchema[0];
    const [regCharity, setRegCharity] = React.useState(false);
    
    async function _submitForm(values, actions){  
        actions.setSubmitting(false);
        let isEligible = values.regCharity == true && values.hasYearPublishedAccounts == true && values.hasYoungPeople == true && values.lastFYTurnover >= 100000 && values.lastFYTurnover <= 1500000 && values.charitableWorkArea.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1;// && values.workPriorities.indexOf(TEXT_NONE_OF_THE_ABOVE) == -1 ;
        let result = {passed:isEligible, checked:true, config:props.config, init:props.init, currentCycle:props.currentCycle, values:{regCharity:regCharity,hasYearPublishedAccounts:values.hasYearPublishedAccounts, hasYoungPeople:values.hasYoungPeople, lastFYTurnover:values.lastFYTurnover ,charitableWorkArea:values.charitableWorkArea,workPriorities:values.workPriorities}};
        actions.setSubmitting(false);
        props.handleEligibility(result);
        actions.setSubmitting(false);
    }

    function _handleSubmit(values, actions) {
        _submitForm(values, actions);
    }

    
    const getChoices = (name) => {
        let {fields} = props.init;
        let field =  fields && fields.value?.filter((f)=>f['name']== name)[0];
        let choices = field && field['choice']['choices']?field['choice']['choices']:[];
        return choices;
    }

    const charitableWorkAreaChoices = props.init && getChoices("BGCharitableWorkArea") && getChoices("BGCharitableWorkArea");
    const introText = props.init && props.init.formTexts.IntroductionText;
    const eligCriteriaText = props.init && props.init.formTexts.EligiblityCriteriaText;
    return (
      <React.Fragment>
        <div className={styles.eligibilityCheck}>
                <Typography component="h1" variant="h4" align="center" className={classes.title} color="secondary">
                    {props.currentCycle.title}
                </Typography>
                <Formik
                    initialValues={eligibilityCheckFormInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                    >
                    {({ isSubmitting }) => (
                    <Form id={formId}> 
                        <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom> 
                                Application Form
                            </Typography>
                            <div dangerouslySetInnerHTML={{__html:introText}}></div>
                            <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom> 
                                Eligibility Criteria
                            </Typography>
                            <div dangerouslySetInnerHTML={{__html:eligCriteriaText}}></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h6" className={classes.subTitle} color="secondary" gutterBottom> 
                                Eligibility Check
                            </Typography>
                            <CheckboxField className={classes.toggleText} name={formField.regCharity.name} label={formField.regCharity.label} fullWidth variant="outlined" color="secondary" labelPlacement="end"/> 
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField className={classes.toggleText} name={formField.hasYearPublishedAccounts.name} label={formField.hasYearPublishedAccounts.label} fullWidth variant="outlined" color="secondary" labelPlacement="end"/> 
                        </Grid>
                        <Grid item xs={12}>
                            <CheckboxField className={classes.toggleText} name={formField.hasYoungPeople.name} label={formField.hasYoungPeople.label} fullWidth variant="outlined" color="secondary" labelPlacement="end"/> 
                        </Grid>
                        <Grid item xs={12}>
                            <InputAdornmentField
                                name={formField.lastFYTurnover.name}
                                label={formField.lastFYTurnover.label}
                                adornment="£"
                                fullWidth
                                type="Number"
                                color="secondary" variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectMultipleField
                                name={formField.charitableWorkArea.name}
                                label={formField.charitableWorkArea.label}
                                data={charitableWorkAreaChoices}
                                value={formField.charitableWorkArea.value}
                                fullWidth
                                variant="outlined"
                                color="secondary"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.wrapper}>
                                <div className={classes.buttons}>
                                    <Button
                                        disabled={isSubmitting}
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        className={classes.buttonNext}
                                    >Next</Button>
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )} 
                                </div>   
                            </div>
                        </Grid>
                    </Grid>
                    </Form>
                )}
                </Formik>
        </div>
      </React.Fragment>
    );
  }

export default EligibilityCheck01
