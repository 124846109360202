export default {
  formId: 'mainForm',
  formField: {
    //personal
    fullname: {
      name: 'fullname',
      label: '1.',
      requiredErrorMsg: 'This question is required'
    },
    company: {
      name: 'company',
      label: '2.',
      requiredErrorMsg: 'This question is required'
    },
    email: {
      name: 'email',
      label: '3.',
      requiredErrorMsg: 'This question is required'
    },
    eventSource: {
      name: 'eventSource',
      label: '4.',
      requiredErrorMsg: 'This question is required'
    },
    eventSourceOther: {
      name: 'eventSourceOther',
      label: 'Other - please specify',
      requiredErrorMsg: 'This question is required'
    },
    comments: {
      name: 'comments',
      label: '4.',
    }
  }
};
